exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3pPO6{margin-top:100px;position:relative;width:100%}._3Beon{width:100%;height:400px;background-image:url(\"https://s3.amazonaws.com/liftit-assets/v2/images/tarjeta-blanca.svg\");background-position:50%;background-repeat:no-repeat;background-size:cover;background-size:auto 95%}._2grMS{position:relative;top:-25px;margin:0 auto}._1LZmC,._2grMS{width:120px;height:120px}._1LZmC{-o-object-fit:cover;object-fit:cover;border-radius:50%;top:-20px}._2QkC4{margin-top:20px;text-align:center}._2QkC4 ._10n74{color:#ff9160;font-size:18px;font-weight:500;line-height:25px;font-family:Circular Std}._2QkC4 ._3x79f{color:#1e192d;font-size:14px;font-weight:300;line-height:23px;font-family:Circular Std}._2QkC4 ._2AID0{opacity:.6;color:#2a2046;font-family:Avenir;font-size:14px;font-style:oblique;font-weight:300;line-height:24px;padding:0 36px}@media (max-width:900px){._2QkC4 ._2AID0{opacity:.6;color:#2a2046;font-family:Avenir;font-size:12px;font-style:oblique;font-weight:300;line-height:24px;width:200px;margin:0 auto}}@media (max-width:1200px){._2QkC4 ._2AID0{line-height:24px;width:200px;font-size:12px;margin:0 auto}}", ""]);

// exports
exports.locals = {
	"cardContainer": "_3pPO6",
	"prueba": "_3Beon",
	"imageContainer": "_2grMS",
	"avatar": "_1LZmC",
	"cardInfo": "_2QkC4",
	"title": "_10n74",
	"subTitle": "_3x79f",
	"info": "_2AID0"
};