import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import PropTypes from 'prop-types'
import s from './Card.css'
// import { SuccessImg } from '../Success/styled';

const arrowBlue =
  'https://s3.amazonaws.com/liftit-assets/v2/images/arrow_blue.svg'

class Card extends React.Component {
  render() {
    const {
      serviceId,
      image,
      title,
      description,
      totalDescription,
      handleClick,
    } = this.props

    const classMiniImg = serviceId === '158' ? s.imgmini : ''

    return (
      <div className={s.card__container}>
        <div className={s.container} onClick={handleClick}>
          <span className={s.gray_circle} />
          <img
            src={image}
            className={`${s.image} ${classMiniImg}`}
            alt=""
          />
          <h3 className={s.title}>{title}</h3>
          <p className={s.description}>{description}</p>

          <div className={s.card__overlay}>
            <div className={s.load} />
            <div>
              <h3>{title}</h3>
              <p>{totalDescription}</p>
            </div>
            <div className={s.arrow}>
              <img src={arrowBlue} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  serviceId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  totalDescription: PropTypes.string.isRequired,
}

export default withStyles(s)(Card)
