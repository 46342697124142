/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { PropTypes } from 'prop-types'
import colors from '../../utils/constants/colors'

const Instagram =
  'https://s3.amazonaws.com/liftit-assets/v2/images/instagram.svg'
const Facebook =
  'https://s3.amazonaws.com/liftit-assets/v2/images/facebook-logo.svg'
const Twitter =
  'https://s3.amazonaws.com/liftit-assets/v2/images/twitter-sign.svg'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Circular Std';
  position: relative;
  background-color: ${colors.white};
  pointer-events: all;
`
const FooterContainer = styled.div`
  width: 90%;
  max-width: 1050px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: space-around;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`
const CountriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
`
const SubFooter = styled.div`
  width: 90%;
  max-width: 1050px;
  height: 70px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const FooterSeparator = styled.div`
  height: 1px;
  width: 60%;
  background-color: ${colors.light_gray};
  margin: 40px 0 20px 0;
`
const SubFooterSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.light_gray};
  margin: 40px 0 20px 0;
`

const FooterItem = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: 90%;
    margin-top: 20px;
  }
`
const DesignedByContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  @media (max-width: 1100px) {
    justify-content: center;
  }
`
const TitleFooter = styled.h3`
  font-size: 17px;
  color: ${colors.dark_gray};
  margin: 7px 0;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    font-size: 16px;
    margin-bottom: 7px;
  }
`
const InfoFooter = styled.h4`
  font-size: 14px;
  color: ${colors.gray};
  font-family: Circular Std;
  font-weight: 100;
  margin: 2px;
  @media (max-width: 1100px) {
    font-size: 13px;
    line-height: 25px;
  }
`
const SubFooterText = styled.h4`
  font-size: 13px;
  color: ${colors.dark_gray};
  font-family: Circular Std;
  font-weight: 300;
  margin: 0 5px;
  @media (max-width: 1100px) {
    text-align: center;
    margin-bottom: 10px;
    font-size: 13px;
  }
`
const Image = styled.img`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  object-fit: contain;
  align-self: center;
`
const IconsSection = styled.div`
  display: flex;
  flex-direction: row;
`

class Footer extends React.Component {
  render() {
    const { t } = this.props
    return (
      <Container>
        <FooterSeparator />
        <FooterContainer>
          <FooterItem>
            <TitleFooter>{t('footer.follow')}</TitleFooter>
            <IconsSection>
              <a
                style={{ marginRight: '10px' }}
                href="https://instagram.com/liftitapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                style={{ marginRight: '10px' }}
                href="https://twitter.com/liftitapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
              <a
                style={{ marginRight: '10px' }}
                href="https://facebook.com/liftit.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
            </IconsSection>
          </FooterItem>
          <FooterItem>
            <TitleFooter>{t('footer.contact')}</TitleFooter>
            <a
              style={{ textDecoration: 'none' }}
              href="mailto:hola@liftit.co?Subject=[S.O.S]¿Cómo te puede ayudar Liftit?"
            >
              <InfoFooter>{t('footer.email')}</InfoFooter>
            </a>
          </FooterItem>
          <CountriesContainer>
            <FooterItem>
              <TitleFooter>
                {t('footer.countries.colombia.city')} 🇨🇴
              </TitleFooter>
              <InfoFooter>
                {t('footer.countries.colombia.address')}
              </InfoFooter>
              <InfoFooter>
                {t('footer.countries.colombia.phone')}
              </InfoFooter>
            </FooterItem>
            <FooterItem>
              <TitleFooter>
                {t('footer.countries.mexico.city')}🇲🇽
              </TitleFooter>
              <InfoFooter>
                {t('footer.countries.mexico.address')}
              </InfoFooter>
            </FooterItem>
            <FooterItem>
              <TitleFooter>Quito 🇪🇨</TitleFooter>
              <InfoFooter>Avenida Amazonas</InfoFooter>
              <InfoFooter>1326 y Alemania</InfoFooter>
            </FooterItem>
            <FooterItem>
              <TitleFooter>Santiago de Chile 🇨🇱</TitleFooter>
              <InfoFooter>Barros Errazuriz 1954</InfoFooter>
              <InfoFooter>Oficina 201 - 203</InfoFooter>
            </FooterItem>
            <FooterItem>
              <TitleFooter>Sao Paulo 🇧🇷</TitleFooter>
              <InfoFooter>Rua Samuel Morse 74</InfoFooter>
              <InfoFooter>Conjunto 173</InfoFooter>
            </FooterItem>
          </CountriesContainer>
        </FooterContainer>
        <SubFooterSeparator />
        <SubFooter>
          <SubFooterText>
            Liftit S.A.S © 2021 All Rights Reserved
          </SubFooterText>
          <DesignedByContainer>
            <SubFooterText>
              {t('footer.design')}
              &nbsp;
            </SubFooterText>
            <Image height="20px" width="20px" src={t('footer.image')} />
            <SubFooterText>{t('footer.site')}</SubFooterText>
          </DesignedByContainer>
        </SubFooter>
      </Container>
    )
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withNamespaces(['components'])(Footer)
