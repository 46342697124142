exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1XKNW{background:#fff;display:-ms-flexbox;display:flex;height:100%;width:100%;-ms-flex-direction:column;flex-direction:column;pointer-events:all;font-family:Eina;overflow:hidden;color:#767676}._3uuoH{height:130px;padding:40px 33px;border-bottom:1px solid #f0f0f0;position:relative}.-i3DS,._3uuoH{width:100%;-webkit-box-sizing:border-box;box-sizing:border-box}.-i3DS{overflow:auto}._1XDBG{text-align:center;max-width:800px;display:block;margin:5em auto}._2-Tyq{margin-left:50px}p{text-align:justify;line-height:24px}b{font-weight:700}ol,ul{list-style:initial;margin-left:50px}ol{list-style:lower-latin}h4,ol{text-align:left}h3{font-family:Eina;font-size:24px;font-weight:500;line-height:30px;margin:0}._1XKNW b,._1XKNW h4,h3{color:#223196}._2WZnv{margin:0;font-weight:300;line-height:18px}._2WZnv,._3nixr{color:#767676;font-family:Eina;font-size:14px}._3nixr{position:absolute;top:35%;right:30px;line-height:19px}._1iuXN{font-weight:900}@media (max-width:768px){h3{font-size:20px;text-align:center}._3nixr{display:none}._2WZnv{margin-top:5px;text-align:center}._3uuoH{height:160px}.-i3DS{padding:0 20px}._2-Tyq{margin-left:20px}ol,ul{text-align:justify;margin-left:20px;-webkit-padding-start:2px;padding-inline-start:2px;-webkit-margin-before:.5ex;margin-block-start:.5ex;-webkit-margin-after:.5ex;margin-block-end:.5ex}}", ""]);

// exports
exports.locals = {
	"terms": "_1XKNW",
	"top": "_3uuoH",
	"father": "-i3DS",
	"wrapper": "_1XDBG",
	"padd": "_2-Tyq",
	"subtitle": "_2WZnv",
	"lastUpdate": "_3nixr",
	"lastUpdateValue": "_1iuXN"
};