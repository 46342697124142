import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from './data_treatment.css'

const DataTreatment = () => (
  <div className={s.terms}>
    <div className={s.top}>
      <h3 className={s.title}>Términos y condiciones LIFTIT S.A.S</h3>
      <p className={s.subtitle}>del servicio para usuarios B2C</p>
      <p className={s.lastUpdate}>
        Última actualización:{' '}
        <span className={s.lastUpdateValue}>Marzo 15 de 2019</span>
      </p>
    </div>
    <div className={s.father}>
      <div className={s.wrapper}>
        <h4>1. Relación contractual</h4>

        <p>
          {
            'Las presentes Condiciones de uso (“Condiciones”) regulan el acceso o uso que usted haga, como persona, desde los países México, Colombia, Ecuador, Brasil y Chile, de aplicaciones, páginas web, contenido, Productos y Servicios (los “Servicios”) puestos a disposición por Liftit S.A.S, una empresa colombiana identificada con el NIT. 901.013.959-2 ubicada en la Carrera 13ª # 98-75 (Piso 3) en la ciudad de Bogotá D.C., correo electrónico: contactenos@liftit.co, conforme a las disposiciones contenidas en la Ley 1581 de 2012, reglamentada parcialmente por el Decreto 1377 del 2013, considerada como responsable y/o encargada del tratamiento de datos personales.'
          }
        </p>

        <h4>Definiciones</h4>
        <div>
          <p>
            {
              'Titular de la información: es la persona natural o jurídica a quien se refiere la información que reposa en un banco de datos, que en este caso es el “Cliente” o “Conductor”, el cual son las personas que puede llegar a vincularse como “Usuario” aceptando estos Términos y Condiciones.'
            }
          </p>

          <p>
            {
              '"Usuario": grupo integrado por las personas que se han registrado debidamente en nuestra plataforma. LIFTIT S.A.S, entidad u organización que recibe o conoce datos personales, es decir que es el responsable del tratamiento de los datos de los titulares de la información en virtud de una relación comercial o de servicio.'
            }
          </p>

          <p>
            {
              '"Plataforma": integra el sitio web que corresponde a la dirección electrónica www.liftit.co.'
            }
          </p>

          <p>
            {
              '"Servicio(s) de Transporte": es el servicio prestado por transportadores vinculados a nuestra plataforma de contacto, a los clientes que los solicitan y que por lo tanto han sido registradas debidamente en la plataforma, pasando a la condición de “Usuario”.'
            }
          </p>

          <p>
            {
              '"Dato Personal": el dato personal hace referencia a cualquier pieza de información vinculada a una o varias personas determinadas o determinables o que puedan asociarse con una persona natural o jurídica y que finalmente serán de carácter público, semiprivado o privado.'
            }
          </p>

          <p>
            {
              '"Temporalidad de la Información": es un principio basado en la necesidad de que el dato del titular no podrá ser suministrado a terceros cuando el mismo deje de servir para la finalidad de la empresa.'
            }
          </p>
        </div>
        <h4>
          LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR
          LOS SERVICIOS.
        </h4>
        <div>
          <p>
            {
              'Mediante su acceso y uso de los Productos y Servicios usted acuerda vincularse jurídicamente por estas Condiciones, que establecen una relación contractual entre usted y Liftit S.A.S. Si usted no acepta estas Condiciones, no podrá acceder o usar los Productos y Servicios. Estas Condiciones sustituyen expresamente los acuerdos o compromisos previos con usted. Liftit S.AS. podrá poner fin de inmediato a estas Condiciones o cualquiera de los Servicios respecto de usted o, en general, dejar de ofrecer o denegar el acceso a los Servicios o cualquier parte de ellos, en cualquier momento y por cualquier motivo.'
            }
          </p>

          <p>
            {
              'Se podrán aplicar condiciones suplementarias a determinados Servicios, como políticas para un evento, una actividad o una promoción particular, y dichas condiciones suplementarias se le comunicarán en relación con los Servicios aplicables. Las condiciones suplementarias se establecen además de las Condiciones, y se considerarán una parte de estas, para los fines de los Servicios aplicables. Las condiciones suplementarias prevalecerán sobre las Condiciones en el caso de conflicto con respecto a los Servicios aplicables.'
            }
          </p>

          <p>
            {
              'Liftit S.A.S podrá modificar las Condiciones relativas a los Productos y Servicios cuando lo considere oportuno. Las modificaciones serán efectivas después de la publicación por parte de Liftit S.A.S de dichas Condiciones actualizadas en esta ubicación o las políticas modificadas o condiciones suplementarias sobre el Servicio aplicable. Su acceso o uso continuado de los Servicios después de dicha publicación constituye su consentimiento a vincularse por las Condiciones y sus modificaciones.'
            }
          </p>

          <p>
            {
              'La recopilación y el uso que hacemos de la información personal en relación con los Servicios es conforme se dispone en la Política de tratamiento de datos de Liftit S.A.S. Liftit S.A.S podrá facilitar a un procesador de reclamaciones o a una aseguradora cualquier información necesaria (incluida su información de contacto) si hubiera quejas, disputas o conflictos, que pudieran incluir un accidente, implicándole a usted y a un tercero (incluido el conductor de la compañía de transporte) y dicha información o dichos datos fueran necesarios para resolver la queja, la disputa o el conflicto.'
            }
          </p>
        </div>
        <h4>2. Los Servicios</h4>
        <div>
          <p>
            {
              'Los Servicios constituyen una plataforma de tecnología que permite a los usuarios organizar y planear el transporte y/o servicios de logística con terceros proveedores independientes de dichos servicios, incluidos terceros transportistas independientes y terceros proveedores logísticos independientes, conforme a un acuerdo con Liftit S.A.S o algunos afiliados de Liftit S.A.S.'
            }
          </p>
        </div>
        <h4>Licencia</h4>
        <div>
          <p>
            {
              'Sujeto al cumplimiento de estas Condiciones, Liftit S.A.S le otorga una licencia limitada, no exclusiva, no sublicenciable, revocable, no transferible para: (i) el acceso y uso de los Productos y Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los Productos y Servicios, en cada caso solo para su uso comercial. Liftit S.A.S y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente.'
            }
          </p>
        </div>
        <h4>Restricciones</h4>
        <div>
          <p>
            {
              'Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de cualquier parte de los Productos y Servicios; (ii) reproducir, modificar, preparar obras derivadas sobre los Productos y Servicios, distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los Productos y Servicios, excepto como se permita expresamente por Liftit S.A.S; (iii) descompilar, realizar ingeniería inversa o desmontar los Productos y Servicios, excepto como se permita por la ley aplicable; (iv) enlazar, reflejar o enmarcar cualquier parte de los Productos y Servicios; (v) causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de los Servicios o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios; o (vi) intentar obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios o sus sistemas o redes relacionados.'
            }
          </p>
        </div>
        <h4>Prestación de los Servicios</h4>
        <div>
          <p>
            {
              'Usted reconoce que parte de los Servicios podrán ponerse a disposición bajo varios productos de Liftit S.A.S asociados con el transporte o los servicios logísticos y tecnológicos, denominados “TMS”, “Backoffice”, “Api”, “App de Lifters”. Asimismo usted reconoce que los Servicios podrán ponerse a disposición bajo dichas marcas u opciones de pedidos en relación con: (i) ciertas subsidiarias o afiliados de Liftit S.A.S; o (ii) Terceros proveedores independientes, incluidos conductores de empresas de red de transportes, titulares del permiso de carta de transporte o titulares de permisos, autorizaciones o licencias de transporte similares.'
            }
          </p>
        </div>
        <h4>Servicios y contenido de Terceros</h4>
        <div>
          <p>
            {
              'Los Servicios podrán ponerse a disposición o ser accesible en relación con servicios y contenido de terceros (incluida la publicidad) que Liftit S.A.S no controle. Usted reconoce que podrán ser de aplicación diferentes condiciones y políticas de privacidad al uso que haga de dichos servicios y contenido de terceros. Liftit S.A.S no respalda dichos servicios y contenido de terceros y en ningún caso Liftit S.A.S será responsable de cualquier producto o servicio de dichos terceros proveedores. Adicionalmente, Android y/o sus correspondientes subsidiarias o afiliados internacionales serán terceros beneficiarios en este contrato si usted accede a los Servicios utilizando productos desarrollados solamente para dispositivos móviles con sistema Android, respectivamente. Estos terceros beneficiarios no son parte de este contrato y no son responsables de la prestación o apoyo de los Servicios de ninguna manera. Su acceso a los Servicios utilizando estos dispositivos se sujeta a las condiciones establecidas en las condiciones de servicio de terceros beneficiarios aplicables.'
            }
          </p>
        </div>
        <h4>Titularidad</h4>
        <div>
          <p>
            {
              'Los Servicios y todos los derechos relativos a estos son y permanecerán de la propiedad de Liftit S.A.S o de sus licenciantes. Ninguna de estas Condiciones ni su uso de los Servicios le transfieren u otorgan ningún derecho: (i) sobre o en relación con los Servicios, excepto en cuanto a la licencia limitada otorgada anteriormente; o bien (ii) a utilizar o mencionar en cualquier modo a los nombres de empresa, logotipos, nombres de producto y servicio, marcas comerciales o marcas de servicio de Liftit S.A.S.'
            }
          </p>
        </div>
        <h4>3. Su uso de los Servicios</h4>
        <h4>Cuentas de usuario</h4>
        <div>
          <p>
            {
              'Con el fin de usar la mayor parte de los aspectos de los Productos y Servicios, usted debe registrarse y mantener activa una cuenta personal de usuario. Para obtener una Cuenta debe tener como mínimo 18 años, o tener la mayoría de edad legal en su jurisdicción (si es distinta a los 18 años). El registro de la cuenta le requiere que comunique a Liftit S.A.S determinada información personal, como su nombre, dirección, número de teléfono móvil, así como por lo menos un método de pago válido (bien una tarjeta de crédito o bien un socio de pago aceptado). Usted se compromete a mantener la información en su Cuenta de forma exacta, completa y actualizada. Si no mantiene la información de Cuenta de forma exacta, completa y actualizada, incluso el tener un método de pago inválido o que haya vencido, podrá resultar en su imposibilidad para acceder y utilizar los Productos y Servicios o en la resolución por parte de Liftit S.A.S de estas Condiciones. Usted es responsable de toda la actividad que ocurre en su Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario y la contraseña de su Cuenta. A menos que Liftit S.A.S permita otra cosa por escrito, usted solo puede poseer una Cuenta.'
            }
          </p>
        </div>
        <h4>Requisitos y conducta del usuario</h4>
        <div>
          <p>
            {
              'El Servicio no está disponible para el uso de personas menores de 18 años. Usted no podrá autorizar a terceros a utilizar su Cuenta, asimismo no podrá permitir a personas menores de 18 años que reciban servicios de transporte o logísticos de Terceros proveedores, a menos que aquellos sean acompañados por usted. No podrá ceder o transferir de otro modo su Cuenta a cualquier otra persona o entidad. Usted acuerda cumplir con todas las leyes aplicables al utilizar los Servicios y solo podrá utilizar los Servicios con fines legítimos (p. ej., no para el transporte de materiales ilegales o peligrosos). En el uso de los Servicios, no causará estorbos, molestias, incomodidades o daños a la propiedad, tanto al Tercero proveedor como a cualquier otra parte. En algunos casos, se le podrá requerir que facilite un documento de identidad u otro elemento de verificación de identidad para el acceso o uso de los Servicios, y usted acepta que se le podrá denegar el acceso o uso de los Servicios si se niega a facilitar el documento de identidad o el elemento de verificación de identidad.'
            }
          </p>
        </div>
        <h4>Mensajes de texto</h4>
        <div>
          <p>
            {
              'Al crear una Cuenta, usted acepta que a través de los Productos, Liftit S.A.S le puede enviar mensajes de texto informativos (SMS) como parte de la actividad comercial normal de su uso de los Servicios. Usted podrá solicitar la no recepción de mensajes de texto informativos (SMS) de Liftit S.A.S en cualquier momento enviando un correo electrónico a contactanos@liftit.co que no desea recibir más dichos mensajes, junto con el número de teléfono del dispositivo móvil que recibe los mensajes. Usted reconoce que solicitar la no recepción de mensajes de texto informativos (SMS) podrá afectar al uso que usted haga de los Servicios.'
            }
          </p>
        </div>
        <h4>Contenido proporcionado por el Usuario</h4>
        <div>
          <p>
            {
              'Liftit S.A.S podrá, a su sola discreción, permitirle cuando considere oportuno, que envíe, cargue, publique o de otro modo ponga a disposición de Liftit S.A.S a través de los Servicios contenido e información de texto, audio y/o visual, incluidos comentarios y opiniones relativos a los Productos y Servicios, iniciación de peticiones de apoyo, así como presentación de admisiones para competiciones y promociones (“Contenido de usuario”). Todo Contenido de usuario facilitado por usted seguirá siendo de su propiedad. No obstante, al proporcionar Contenido de usuario a Liftit S.A.S, usted otorga una licencia mundial, perpetua, irrevocable, transferible, libre de regalías, con derecho a sublicenciar, usar, copiar, modificar, crear obras derivadas, distribuir, exhibir públicamente, presentar públicamente o de otro modo explotar de cualquier manera dicho Contenido de usuario en todos los formatos y canales de distribución, conocidos ahora o ideados en un futuro (incluidos en relación con los Servicios y el negocio de Liftit S.A.S y en sitios y servicios de terceros), sin más aviso o consentimiento de usted y sin requerirse el pago a usted o a cualquier otra persona o entidad.'
            }
          </p>
          <p>
            {
              'Usted declara y garantiza que: (i) es el único y exclusivo propietario de todo el Contenido de usuario o que tiene todos los derechos, licencias, consentimientos y permisos necesarios para otorgar a Liftit S.A.S la licencia al Contenido de usuario como establecido anteriormente; y (ii) ni el Contenido de usuario ni su presentación, carga, publicación o puesta a disposición de otro modo de dicho Contenido de usuario, ni el uso por parte de Liftit S.A.S del Contenido de usuario como está aquí permitido, infringirán, malversarán o violarán la propiedad intelectual o los derechos de propiedad de un tercero o los derechos de publicidad o privacidad o resultarán en la violación de cualquier ley o reglamento aplicable.'
            }
          </p>
          <p>
            {
              'Usted acuerda no proporcionar Contenido de usuario que sea difamatorio, calumnioso, odioso, violento, obsceno, pornográfico, ilícito o de otro modo ofensivo, como determine Liftit S.A.S, a su sola discreción, tanto si dicho material pueda estar protegido o no por la ley. Liftit S.A.S podrá, a su sola discreción y en cualquier momento y por cualquier motivo, sin avisarle previamente, revisar, controlar o eliminar Contenido de usuario, pero sin estar obligada a ello.'
            }
          </p>
        </div>
        <h4>Acceso a la red y dispositivos</h4>
        <div>
          <p>
            {
              'Usted es responsable de obtener el acceso a la red de datos necesario para utilizar los Productos y Servicios. Podrán aplicarse las tarifas y tasas de datos y mensajes de su red móvil si usted accede o utiliza los Servicios desde un dispositivo inalámbrico y usted será responsable de dichas tarifas y tasas. Usted es responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para acceder y utilizar los Servicios y Aplicaciones y cualquier actualización de estos. Uber no garantiza que los Servicios, o cualquier parte de estos, funcionen en cualquier hardware o dispositivo particular. Además, los Servicios podrán ser objeto de disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.'
            }
          </p>
        </div>
        <h4>4. Pago</h4>
        <div>
          <p>
            {
              'Usted entiende que el uso de los Productos y Servicios puede derivar en cargos. Los Cargos incluirán los impuestos aplicables cuando se requiera por ley. Los Cargos pagados por usted son definitivos y no reembolsables, a menos que Liftit S.A.S determine lo contrario.'
            }
          </p>
          <p>
            {
              'Todos los Cargos son pagaderos de acuerdo al convenio realizado con Liftit S.A.S y el pago se facilitará por Liftit Cargo S.A.S utilizando el método de pago preferido indicado en su Cuenta, y después de ello Liftit Cargo S.A.S le enviará un recibo por correo electrónico. Si se determina que el método de pago de su Cuenta principal ha caducado, es inválido o de otro modo no sirve para cobrarle, usted acepta que Liftit Cargo S.A.S, como agente de cobro limitado del Tercero proveedor, utilice un método de pago secundario en su Cuenta, si estuviera disponible.'
            }
          </p>
          <p>
            {
              'Liftit Cargo S.A.S, en cualquier momento y a su sola discreción, se reserva el derecho de establecer, eliminar y/o revisar los Cargos para alguno o todos los servicios o bienes obtenidos a través del uso de los Servicios. Además, usted reconoce y acepta que los Cargos aplicables en determinadas zonas geográficas podrán incrementar sustancialmente durante los periodos de alta demanda y/o de acuerdo a la negociación/servicio realizado. Liftit S.A.S usará los esfuerzos razonables para informarle de los Cargos que podrán aplicarse, siempre y cuando usted sea responsable de los Cargos incurridos en su Cuenta, independientemente de que usted conozca dichos Cargos o los importes de estos. Liftit S.A.S podrá, cuando lo considere oportuno, proporcionar a determinados usuarios ofertas promocionales y descuentos que pueden resultar en el cobro de diferentes importes por estos o similares servicios o bienes obtenidos a través del uso de los Servicios, y usted acepta que dichas ofertas promocionales y descuentos, a menos que también se pongan a su disposición, no se tendrán en cuenta en el uso por su parte de los Servicios o los Cargos aplicados a usted.'
            }
          </p>
        </div>
        <h4>
          5. Renuncias; Limitación de responsabilidad; Indemnidad.
          RENUNCIA.
        </h4>
        <div>
          <p>
            {
              'LOS SERVICIOS SE PROPORCIONAN “TAL CUAL” Y “COMO DISPONIBLES”. Liftit S.A.S RENUNCIA A TODA DECLARACIÓN Y GARANTÍA, EXPRESA, IMPLÍCITA O ESTATUTARIA, NO EXPRESAMENTE ESTABLECIDA EN ESTAS CONDICIONES, INCLUIDAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN PARTICULAR Y NO VIOLACIÓN. ADEMÁS, Liftit S.A.S NO HACE DECLARACIÓN NI PRESTA GARANTÍA ALGUNA RELATIVA A LA FIABILIDAD, PUNTUALIDAD, CALIDAD, IDONEIDAD O DISPONIBILIDAD DE LOS SERVICIOS O CUALQUIERA DE LOS SERVICIOS O BIENES SOLICITADOS A TRAVÉS DEL USO DE LOS SERVICIOS, O QUE LOS SERVICIOS NO SERÁN INTERRUMPIDOS O ESTARÁN LIBRES DE ERRORES. Liftit S.A.S NO GARANTIZA LA CALIDAD, IDONEIDAD, SEGURIDAD O HABILIDAD DE LOS TERCEROS PROVEEDORES. USTED ACUERDA QUE TODO RIESGO DERIVADO DE SU USO DE LOS SERVICIOS Y CUALQUIER SERVICIO O BIEN SOLICITADO EN RELACIÓN CON AQUELLOS SERÁ ÚNICAMENTE SUYO, EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY APLICABLE.'
            }
          </p>
        </div>
        <h4>LIMITACIÓN DE RESPONSABILIDAD</h4>
        <div>
          <p>
            {
              'LIFTIT S.A.S NO SERÁ RESPONSABLE DE DAÑOS INDIRECTOS, INCIDENTALES, ESPECIALES, EJEMPLARES, PUNITIVOS O EMERGENTES, INCLUIDOS EL LUCRO CESANTE, LA PÉRDIDA DE DATOS, LA LESIÓN PERSONAL O EL DAÑO A LA PROPIEDAD, NI DE PERJUICIOS RELATIVOS, O EN RELACIÓN CON, O DE OTRO MODO DERIVADOS DE CUALQUIER USO DE LOS SERVICIOS, INCLUSO AUNQUE LIFTIT S.A.S HAYA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS. LIFTIT S.A.S NO SERÁ RESPONSABLE DE CUALQUIER DAÑO, RESPONSABILIDAD O PÉRDIDA QUE DERIVEN DE: (I) SU USO O DEPENDENCIA DE LOS SERVICIOS O SU INCAPACIDAD PARA ACCEDER O UTILIZAR LOS SERVICIOS; O (ii) CUALQUIER TRANSACCIÓN O RELACIÓN ENTRE USTED Y CUALQUIER TERCERO PROVEEDOR, AUNQUE LIFTIT S.A.S HUBIERA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS. LIFTIT S.A.S NO SERÁ RESPONSABLE DEL RETRASO O DE LA FALTA DE EJECUCIÓN RESULTANTE DE CAUSAS QUE VAYAN MÁS ALLÁ DEL CONTROL RAZONABLE DE LIFTIT S.A.S. USTED PODRÁ UTILIZAR LOS SERVICIOS DE LIFTIT S.A.S  PARA SOLICITAR Y PLANIFICAR SERVICIOS DE TRANSPORTE DE CARGA, BIENES O LOGÍSTICOS CON TERCEROS PROVEEDORES, PERO ACEPTA QUE LIFTIT S.A.S NO TIENE RESPONSABILIDAD ALGUNA HACIA USTED EN RELACIÓN CON CUALQUIER SERVICIO DE TRANSPORTE, BIENES O LOGÍSTICO PRESTADO A USTED POR TERCEROS PROVEEDORES QUE NO SEA COMO SE HA ESTABLECIDO EXPRESAMENTE EN ESTAS CONDICIONES.'
            }
          </p>
          <p>
            {
              'LAS LIMITACIONES Y LA RENUNCIA EN ESTE APARTADO 5 NO PRETENDEN LIMITAR LA RESPONSABILIDAD O ALTERAR SUS DERECHOS COMO CONSUMIDOR QUE NO PUEDAN EXCLUIRSE SEGÚN LA LEY APLICABLE.'
            }
          </p>
        </div>
        <h4>Indemnidad</h4>
        <div>
          <p>
            {
              'Usted acuerda mantener indemnes y responder frente a Liftit S.A.S y sus consejeros, directores, empleados y agentes por cualquier reclamación, demanda, pérdida, responsabilidad y gasto (incluidos los honorarios de abogados) que deriven de: (i) su uso de los Servicios o Productos o bienes obtenidos a través de su uso de los Servicios; (ii) su incumplimiento o violación de cualquiera de estas Condiciones; (iii) el uso por parte de Liftit S.A.S de su Contenido de usuario; o (iv) su infracción de los derechos de cualquier tercero, incluidos Terceros proveedores.'
            }
          </p>
        </div>
        <h4>6. Legislación aplicable; Arbitraje</h4>
        <div>
          <p>
            {
              'Toda interpretación, actuación judicial o administrativa derivada del tratamiento de los datos personales estará sujeta a las normas de protección personal establecidas en la República de Colombia y las autoridades administrativas o jurisdiccionales competentes para la resolución de cualquier inquietud, queja o demanda sobre las mismas serán las de la República de Colombia.'
            }
          </p>
          <p>
            {
              'La presente Política de Protección de datos personales rige a partir de su publicación en el sitio web de LIFTIT S.A.S'
            }
          </p>
        </div>
        <h4>7. Otras disposiciones</h4>
        <h4>Reclamaciones por infracción de derechos de autor</h4>
        <div>
          <p>
            {
              'Las reclamaciones por infracción de derechos de autor deberán enviarse al agente designado de Liftit S.A.S. Visite la página web de Liftit en www.liftit.co para obtener las direcciones designadas e información adicional.'
            }
          </p>
        </div>
        <h4>Notificaciones</h4>
        <div>
          <p>
            {
              'Liftit S.A.S podrá notificar por medio de una notificación general en los Servicios, mediante un correo electrónico enviado a su dirección electrónica en su Cuenta o por comunicación escrita enviada a su dirección, según lo dispuesto en su Cuenta. Usted podrá notificar a Liftit por comunicación escrita a la dirección de Liftit S.A.S en la carrera 13a # 98 - 75 en Bogotá, Colombia.'
            }
          </p>
        </div>
        <h4>Disposiciones generales</h4>
        <div>
          <p>
            {
              'No podrá ceder ni transferir estas Condiciones, en todo o en parte, sin el consentimiento previo por escrito de Liftit S.A.S. Usted da su aprobación a Liftit S.A.S para ceder o transferir estas Condiciones, en todo o en parte, incluido a: (i) una subsidiaria o un afiliado; (ii) un adquirente del capital, del negocio o de los activos de Liftit S.A.S; o (iii) un sucesor por fusión. No existe entre usted, Liftit S.A.S o cualquier Tercer proveedor una empresa conjunta o relación de socios, empleo o agencia como resultado del contrato entre usted y Liftit S.A.S o del uso de los Servicios.'
            }
          </p>
          <p>
            {
              'Si cualquier disposición de estas Condiciones se considerara ilegal, nula o inexigible, ya sea en su totalidad o en parte, de conformidad con cualquier legislación, dicha disposición o parte de esta se considerará que no forma parte de estas Condiciones, aunque la legalidad, validez y exigibilidad del resto de las disposiciones de estas Condiciones no se verá afectada. En ese caso, las partes deberán reemplazar dicha disposición ilegal, nula o inexigible, en todo o en parte por una disposición legal, válida y exigible que tenga, en la medida de lo posible, un efecto similar al que tenía la disposición ilegal, nula o inexigible, dados los contenidos y el propósito de estas Condiciones. Estas Condiciones constituyen el contrato íntegro y el entendimiento entre las partes en relación con el objeto y sustituye y reemplaza a todos los contratos o acuerdos anteriores o contemporáneos en relación con dicho objeto. En estas Condiciones, las palabras “incluido/a/os/as” e “incluye/n” significan “incluido, de forma meramente enunciativa”.'
            }
          </p>
        </div>
      </div>
    </div>
  </div>
)
export default withStyles(s)(DataTreatment)
