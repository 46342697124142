import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import ModalActions from '../../reducers/modal'
import propTypesModels from '../../utils/constants/propTypesModels'

import s from './Modal.css'

const CloseModalIcon =
  'https://s3.amazonaws.com/liftit-assets/v2/images/cerrar_modal.svg'

ReactModal.setAppElement('body')

class Modal extends React.Component {
  state = {
    mobile: false,
  }
  componentDidMount() {
    const changeWidth = x => {
      if (x.matches) {
        this.setState({
          mobile: true,
        })
      } else {
        this.setState({
          mobile: false,
        })
      }
    }

    const x = window.matchMedia('(max-width: 768px)')
    x.addListener(changeWidth)
    changeWidth(x)
    this.props.renderForm(true)
  }

  render() {
    const { modal, close, renderForm, t, alertTypeModal } = this.props
    let script = null
    if (typeof document !== 'undefined') {
      script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(script)
    }

    return (
      <ReactModal
        isOpen={modal.show}
        className={s.modal}
        style={this.state.mobile ? { content: { width: '95%' } } : {}}
        overlayClassName={s.modalOverlay}
        onRequestClose={modal.close || close}
      >
        <div
          className={
            modal.form
              ? `${s.buttonCloseContainer} ${s.buttonForm}`
              : s.buttonCloseContainer
          }
        >
          <div className={s.buttonClose} onClick={modal.close || close}>
            <img className={s.imgClose} src={CloseModalIcon} alt="" />
          </div>
        </div>

        <div
          className={
            modal.form
              ? `${s.modalContainer} ${s.modalFormContainer}`
              : s.modalContainer
          }
        >
          {modal.form ? (
            <div className={s.modalContent}>
              {modal.renderForm &&
                script &&
                script.addEventListener('load', () => {
                  if (window.hbspt) {
                    window.hbspt.forms.create({
                      portalId: '4838246',
                      formId: '108c5a17-7639-4506-beb2-67d60a3ceff3',
                      target: '#hubspotForm',
                    })
                  }

                  renderForm(false)
                })}
              <div className={s.hbsptForm} id="hubspotForm" />
            </div>
          ) : (
            <div className={s.modalContent}>
              <h2 className={s.modalContentTitle}>{t(modal.title)}</h2>
              <p>{t(modal.text)}</p>
              <div className={s.contentButton}>
                <button
                  className={`${s.button} ${s.buttonOrange}`}
                  onClick={modal.link || modal.close || close}
                >
                  {t(modal.buttonText)}
                </button>
                {alertTypeModal ? (
                  <button
                    className={`${s.button} ${s.buttonOrangeClose}`}
                    onClick={modal.close || close}
                  >
                    {t(modal.buttonText2)}
                  </button>
                ) : (
                  <div />
                )}
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  modal: PropTypes.shape(propTypesModels.Modal).isRequired,
  renderForm: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  alertTypeModal: PropTypes.string,
}

const mapStateToDispatch = dispatch => ({
  close: () => {
    dispatch(ModalActions.closeModal())
  },

  renderForm: render => {
    dispatch(ModalActions.setRenderForm(render))
  },
})

const mapStateToProps = state => ({
  modal: state.modal,
  alertTypeModal: state.session.alertTypeModal,
})

const Modals = connect(
  mapStateToProps,
  mapStateToDispatch,
)(withStyles(s)(Modal))

export default withNamespaces(['error', 'components'])(Modals)
