import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import PropTypes from 'prop-types'
import s from './cardSlider.css'

class CardSlider extends React.Component {
  render() {
    return (
      <div className={s.cardContainer}>
        <div className={s.prueba}>
          <div className={s.imageContainer}>
            <img
              className={s.avatar}
              src={this.props.urlImage}
              alt=""
            />
          </div>
          <div className={s.cardInfo}>
            <div className={s.title}>{this.props.title}</div>
            <div className={s.subtitle}>{this.props.subTitle}</div>
            <div className={s.info}>{this.props.info}</div>
          </div>
        </div>
      </div>
    )
  }
}

CardSlider.propTypes = {
  urlImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  info: PropTypes.string,
}

export default withStyles(s)(CardSlider)
