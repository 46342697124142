// Libs ========================================================================
import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from './data_treatment.css'

const DataTreatment = () => (
  <div className={s.terms}>
    <div className={s.top}>
      <h3 className={s.title}>
        POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES
      </h3>
    </div>
    <div className={s.father}>
      <header className={s.wrapper}>
        <p>
          En cumplimiento de las disposiciones de la Ley 1581 de 2012
          “por la cual se dictan disposiciones generales para la
          protección de datos personales” y del Decreto reglamentario
          1377 de 2013 y el Decreto 1074 de 2015 “por el cual se
          reglamenta parcialmente la Ley 1581 de 2012”, LIFTIT&nbsp;
          S.A.S.,&nbsp; ​publica la presente política la cual contiene
          los lineamientos que aplica para el tratamiento de los datos
          personales de sus clientes, proveedores y empleados.
        </p>
        <h4>Responsable del Tratamiento de Datos Personales:</h4>
        <p>
          El responsable del tratamiento de datos personales
          es&nbsp;LIFTIT&nbsp;S.A.S. con Nit 901.013.959-2 con la
          siguiente información:&nbsp;
        </p>
        <div className={s.padd}>
          <p>
            <b>Dirección: </b>Carrera 13ª # 98-75 (Piso 3) / Chicó Norte
            Bogotá D.C., Colombia
          </p>
          <p>
            <b>Correo electrónico: </b>hola@liftit.co
          </p>
          <p>
            <b>WhatsApp: </b>+57 321 467 46 93
          </p>
          <p>
            <b>Pagina web: </b>www.liftit.co
          </p>
        </div>
        <h4>Tratamiento de los Datos Personales:</h4>
        <p>
          Para el tratamiento, recolección, almacenamiento, uso,
          circulación, supresión y revocación entre otras, de los datos
          personales de personas naturales, LIFTIT S.A.S., obtiene
          autorización previa e informada del Titular (persona natural
          cuyos datos personales sean objeto de Tratamiento) o quien se
          encuentre legitimado de conformidad con lo establecido en el
          artículo 20 del Decreto 1377 de 2013, la cual podrá ser
          concedida por medio escrito, oral o mediante conductas
          inequívocas del titular que permitan concluir de forma
          razonable que otorgó la autorización y que pueda ser objeto de
          consulta posterior por su Titular o de quien se encuentre
          legitimado para ejercer los derechos del Titular conforme a la
          ley.
        </p>
        <p>
          Para efectos de la presente política, los términos que se
          señalan a continuación tendrán los siguientes significados,
          los cuales fueron tomados de la Ley 1581 de 2012 y del Decreto
          1377 de 2013:
        </p>
        <p>
          <b>Autorización: </b> Consentimiento previo, expreso e
          informado del Titular para llevar a cabo el Tratamiento de
          datos personales.
        </p>
        <p>
          <b>Base de Datos: </b>Conjunto organizado de datos personales
          que sea objeto de Tratamiento.
        </p>
        <p>
          <b>Dato personal: </b>Cualquier información vinculada o que
          pueda asociarse a una o varias personas naturales determinadas
          o determinables.{' '}
        </p>
        <p>
          <b>Dato público: </b>Es el dato que no sea semiprivado,
          privado o sensible. Son considerados datos públicos, entre
          otros, los datos relativos al estado civil de las personas, a
          su profesión u oficio y a su calidad de comerciante o de
          servidor público.
        </p>
        <p>
          <b>Encargado del Tratamiento: </b>Persona natural o jurídica,
          pública o privada, que por sí misma o en asocio con otros,
          realice el Tratamiento de datos personales por cuenta del
          Responsable del Tratamiento.{' '}
        </p>
        <p>
          <b>Responsable del Tratamiento: </b>Persona natural o
          jurídica, pública o privada, que por sí misma o en asocio con
          otros, decida sobre la base de datos y/o el Tratamiento de los
          datos.{' '}
        </p>
        <p>
          <b>Titular: </b>Persona natural cuyos datos personales sean
          objeto de Tratamiento.
        </p>
        <p>
          <b>Tratamiento: </b>Cualquier operación o conjunto de
          operaciones sobre datos personales, tales como la recolección,
          almacenamiento, uso, circulación o supresión.{' '}
        </p>
        <p>
          <b>Aviso de privacidad: </b>comunicación verbal o escrita
          generada por el Responsable dirigida al Titular para el
          Tratamiento de sus datos personales, mediante la cual se le
          informa acerca de la existencia de las políticas de
          Tratamiento de información que le serán aplicables, la forma
          de acceder a las mismas y las finalidades del Tratamiento que
          se pretende dar a los datos personales.{' '}
        </p>
        <p>
          <b>Datos sensibles: </b>se entiende por datos sensibles
          aquellos que afectan la intimidad del Titular o cuyo uso
          indebido puede generar su discriminación, tales como aquellos
          que revelen el origen racial o étnico, la orientación
          política, las convicciones religiosas o filosóficas, la
          pertenencia a sindicatos, organizaciones sociales, de derechos
          humanos o que promueva intereses de cualquier partido político
          o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la
          salud, a la vida sexual y los datos biométricos.&quot;
        </p>
        <p>
          Para el procedimiento para la captura de datos personales por
          parte de LIFTIT S.A.S., en el desarrollo de su actividad, son
          las siguientes:{' '}
        </p>
        <ol>
          <li>Registro de información de Clientes.</li>
          <li>Registro de información de Proveedores.</li>
          <li>Captura de información a través del sitio web.</li>
          <li>
            Captura de información a través de programas de mercadeo.
          </li>
          <li>
            Selección de personal y manejo de información para nómina.
          </li>
          <li>
            Captura de información en actividades de servicio al
            cliente.
          </li>
        </ol>
        <p>
          Durante la ejecución de estas actividades o de otras donde se
          presente la captura de datos personales, el reporte,
          divulgación, uso, consulta, eliminación o supresión, reenvío,
          administración, acceso, conservación, almacenamiento, archivo,
          análisis, copia, procesamiento e inclusión de dichos datos
          personales dentro de sus bases de datos en su servidor ,
          LIFTIT S.A.S., deberá ceñirse a los términos de la Ley 1581 de
          2012, el Decreto reglamentario 1377 de 2013 y demás normas
          concordantes, en el Tratamiento de los datos personales de
          terceros, para lo cual obtendrá previamente autorización de su
          Titular. Previo a compartir datos personales con terceros,
          aliados, contratistas, proveedores, deberá cerciorarse de
          haber obtenido previamente la autorización del Titular de los
          datos para darle ese uso.
        </p>
        <h4>
          Finalidad con la que se efectúa la recolección de datos
          personales y tratamiento de los mismos:
        </h4>
        <p>
          LIFTIT S.A.S. podrá hacer uso de los datos personales para: a)
          Ejecutar la relación contractual existente con sus clientes,
          proveedores y trabajadores, incluida el pago de obligaciones
          contractuales; b) Proveer los servicios y/o los productos
          requeridos por sus usuarios; c) Informar sobre nuevos
          productos o servicios y/o sobre cambios en los mismos; d)
          Evaluar la calidad del servicio; e) Realizar estudios internos
          sobre hábitos de consumo; f) Enviar al correo físico,
          electrónico, celular o dispositivo móvil, vía mensajes de
          texto (SMS y/o MMS) o a través de cualquier otro medio análogo
          y/o digital de comunicación creado o por crearse, información
          comercial y de acuerdo a su objeto social; g) Desarrollar el
          proceso de selección, evaluación, y vinculación laboral; h)
          Soportar procesos de auditoría interna o externa; i) Registrar
          la información de empleados en las bases de datos de LIFTIT
          S.A.S.; i) Los indicados en la autorización otorgada por el
          titular del dato o descritos en el aviso de privacidad
          respectivo, según sea el caso; j) Suministrar, compartir,
          enviar o entregar sus datos personales a empresas filiales,
          vinculadas, o subordinadas de LIFTIT S.A.S., ubicadas en
          Colombia o cualquier otro país en el evento que dichas
          compañías requieran la información para los fines aquí
          indicados; k) Manejo interno a efectos de registro de sus
          clientes; l) El envío de correspondencia, correos electrónicos
          o contacto telefónico con sus clientes, proveedores y
          empleados; m) Actualización de las bases de datos; n)
          Selección de personal, administración de contrataciones,
          manejo de relaciones laborales y cumplimiento de las
          obligaciones derivadas de la misma, otorgamiento de beneficios
          a sus empleados por sí mismo o a través de terceros, así como
          permitir el acceso de los empleados a los recursos
          informáticos de la empresa. o) Gestiones de cobranzas o
          comercial.
        </p>
        <h4>
          Principios aplicables al tratamiento de datos personales:
        </h4>
        <p>
          El tratamiento de datos personales en LIFTIT S.A.S. se regirá
          por los siguientes principios: a) Principio de finalidad: El
          Tratamiento de los datos personales recogidos debe obedecer a
          una finalidad legítima, la cual debe ser informada al Titular;
          b) Principio de libertad: El Tratamiento sólo puede llevarse a
          cabo con el consentimiento, previo, expreso e informado del
          Titular. Los datos personales no podrán ser obtenidos o
          divulgados sin previa autorización, o en ausencia de mandato
          legal o judicial que releve el consentimiento; c) Principio de
          veracidad o calidad: La información sujeta a Tratamiento debe
          ser veraz, completa, exacta, actualizada, comprobable y
          comprensible. No será efectuado el Tratamiento de datos
          parciales, incompletos, fraccionados o que induzcan a error;
          d) Principio de transparencia: En el Tratamiento debe
          garantizarse el derecho del Titular a obtener de LIFTIT S.A.S.
          en cualquier momento y sin restricciones, información acerca
          de la existencia de datos que le conciernen; e) Principio de
          acceso y circulación restringida: El Tratamiento se sujeta a
          los límites que se derivan de la naturaleza de los datos
          personales, de las disposiciones de la presente ley y la
          Constitución. Los datos personales, salvo la información
          pública, y lo dispuesto en la autorización otorgada por el
          titular del dato, no podrán estar disponibles en Internet u
          otros medios de divulgación o comunicación masiva, salvo que
          el acceso sea técnicamente controlable para brindar un
          conocimiento restringido sólo a los Titulares o terceros
          autorizados; f) Principio de seguridad: La información sujeta
          a Tratamiento por parte de LIFTIT S.A.S. se deberá proteger
          mediante el uso de las medidas técnicas, humanas y
          administrativas que sean necesarias para otorgar seguridad a
          los registros evitando su adulteración, pérdida, consulta, uso
          o acceso no autorizado o fraudulento; g) Principio de
          confidencialidad: Todas las personas que intervengan en el
          Tratamiento de datos personales están obligadas a garantizar
          la reserva de la información, inclusive después de finalizada
          su relación con alguna de las labores que comprende el
          Tratamiento.
        </p>
        <h4>Vigencia de las bases de datos:</h4>
        <p>
          Las bases de datos de LIFTIT S.A.S., tendrán una vigencia
          igual al periodo de tiempo en que se mantenga la finalidad o
          finalidades del tratamiento en cada base de datos o aquel
          requerido de acuerdo a las normas contables, comerciales,
          tributarias, laborales, o cualquiera aplicable según la
          materia, y a los aspectos administrativos, contables,
          fiscales, jurídicos e históricos de la información almacenadas
          en las mismas, con un plazo máximo de cinco años. Como
          consecuencia de lo anterior, y siempre y cuando ello no vaya
          en contravía del cumplimiento de una norma vigente, una vez
          cumplida la o las finalidades del tratamiento de los datos,
          LIFTIT S.A.S., podrá proceder a su supresión. No obstante, lo
          anterior, los datos personales serán conservados cuando se
          requieran para dar cumplimiento a una obligación legal o
          contractual.
        </p>
        <h4>Derechos de los Titulares de los Datos:</h4>
        <p>
          En aplicación de los términos de la ley, LIFTIT S.A.S., asume
          el papel de Responsable del Tratamiento en todos los casos
          cuando recolecte, almacene, use, circule, suprima o transfiera
          o transmita datos de Titulares en alguna de sus actividades
          como empresa, ya sea por sí misma o a través de terceros. Los
          derechos que le asisten conforme a la ley al Titular de los
          datos son los siguientes:
          <ol>
            <li>
              Conocer, actualizar y rectificar sus datos personales,
              este derecho se podrá ejercer, entre otros frente a datos
              parciales, inexactos, incompletos, fraccionados, que
              induzcan a error, o aquellos cuyo Tratamiento esté
              expresamente prohibido o no haya sido autorizado.
            </li>
            <li>
              Ser informado por el Responsable del Tratamiento o el
              Encargado del Tratamiento, previa solicitud, respecto del
              uso que le ha dado a sus datos personales.
            </li>
            <li>
              Presentar ante la Superintendencia de Industria y Comercio
              quejas por infracciones a lo dispuesto en la presente ley
              y las demás normas que la modifiquen, adicionen o
              complementen.
            </li>
            <li>
              Revocar la autorización y/o solicitar la supresión del
              dato cuando en el Tratamiento no se respeten los
              principios, derechos y garantías constitucionales y
              legales. La revocatoria y/o supresión procederá cuando la
              Superintendencia de Industria y Comercio haya determinado
              que en el Tratamiento el Responsable o Encargado han
              incurrido en conductas contrarias a esta ley y a la
              Constitución.
            </li>
            <li>
              Acceder en forma gratuita a sus datos personales que hayan
              sido objeto de Tratamiento.
            </li>
          </ol>
        </p>
        <h4>
          Tratamiento de Datos Sensibles y datos personales de niños,
          niñas y adolescentes:
        </h4>
        <p>
          El Tratamiento sobre datos sensibles y los pertenecientes a
          niños, niñas y adolescentes está prohibido. No obstante, lo
          anterior, LIFTIT S.A.S., realiza el tratamiento de datos de
          este tipo en los siguientes casos única y exclusivamente
          cuando medie autorización previa y expresa de su Titular y
          representantes
        </p>
        <h4>Datos sensibles:</h4>
        <p>
          LIFTIT S.A.S., realiza el tratamiento de datos sensibles
          autorizados por el Artículo 6 de la Ley 1581 de 2012,
          capturados de sus empleados y con fines únicos y exclusivos de
          manejo de la relación laboral con la empresa y para dar
          cumplimiento a las obligaciones legales, contractuales y/o
          convencionales derivadas de la misma, en vigencia y/o
          finalizada la misma. Lo anterior sin perjuicio de las
          autorizaciones que puedan conceder los titulares en los
          términos del Artículo 6 del Decreto 1377 de 2013, dichos datos
          son manejados en bases de datos del área de recursos humanos
          de LIFTIT S.A.S. <br />
          Para el tratamiento de datos sensibles LIFTIT S.A.S. deberá:
        </p>
        <ol>
          <li>
            Informar al Titular que por tratarse de datos sensibles no
            está obligado a autorizar su Tratamiento.
          </li>
          <li>
            Informar al Titular de forma explícita y previa, además de
            los requisitos generales de la autorización para la
            recolección de cualquier tipo de dato personal, cuáles de
            los datos que serán objeto de tratamiento son sensibles y la
            finalidad del tratamiento, así como obtener su
            consentimiento expreso.
          </li>
          <li>
            No podrá condicionar ninguna actividad al suministro de
            datos personales sensibles.
          </li>
        </ol>
        <p>
          El Tratamiento de este tipo de datos será permitido siempre y
          cuando responda y respete el interés superior de los niños,
          niñas y adolescentes, y se asegure el respeto de sus derechos
          fundamentales.
        </p>
        <p>
          Cumplidos los anteriores requisitos, LIFTIT S.A.S., debe
          obtener la autorización de forma verbal del representante
          legal del niño, niña o adolescente para la captura,
          almacenamiento, envío a terceros y uso de los datos con los
          fines antes señalados, previo ejercicio del menor de su
          derecho a ser escuchado, opinión que será valorada teniendo en
          cuenta la madurez, autonomía y capacidad para entender el
          asunto. Para efectos del ejercicio de los derechos antes
          descritos tanto el titular como la persona que lo represente
          deberá demostrar su identidad y, de ser el caso, la calidad en
          virtud de la cual representa al titular. Los derechos de los
          menores de edad serán ejercidos por medio de las personas que
          estén facultadas para representarlos, quien será garante de la
          defensa de sus derechos.
        </p>
        <h4>Atención de Consultas, Solicitudes y Reclamos:</h4>
        <p>
          LIFTIT S.A.S., deberá permitir el acceso gratuito del Titular
          de los datos a su información cuando así lo requiera para
          realizar consultas, así como garantizar el derecho a los
          Titulares de presentar reclamos y solicitudes de corrección,
          actualización o supresión de datos personales y podrá recibir
          las solicitudes antes señaladas sobre Datos Personales de:{' '}
        </p>
        <ol>
          <li>El Titular del dato</li>
          <li>
            De los causahabientes, representantes legales o apoderados
            de los Titulares;
          </li>
          <li>
            De entidades públicas o administrativas en ejercicio de sus
            funciones legales o
          </li>
          <li>
            Por orden judicial y de terceros autorizados por el Titular
            o por la ley.
          </li>
          <li>Por estipulación a favor de otro o para otro.</li>
        </ol>
        <p>
          LIFTIT S.A.S., permitirá el recibo de consultas, solicitudes y
          reclamos por medio escrito a su dirección Carrera 13ª # 98-75
          (Piso 3) en la ciudad de Bogotá D.C., correo electrónico
          hola@liftit.co. Las consultas sobre datos personales serán
          resueltas por LIFTIT S.A.S., en un término máximo de quince
          días hábiles contados a partir de la fecha de recibo de la
          misma. Cuando no fuere posible atender la consulta dentro de
          dicho término, se informará al Titular o sus causahabientes,
          manifestando los motivos de la demora y señalando la fecha en
          que se atenderá su consulta, la cual en ningún caso podrá
          superar los cinco días hábiles siguientes al vencimiento del
          primer término. En caso que la consulta sea realizada vía
          sitio web, deberá informarse al Titular que debe hacerla por
          escrito al correo electrónico hola@Liftit.co con el fin de
          mantener prueba de esta. El trámite de estas consultas y
          reclamos será gratuito, excepto en los casos señalados
          expresamente por la ley. La solicitud de supresión de la
          información y la revocatoria de la autorización no procederán
          cuando el Titular del dato tenga un deber legal o contractual
          de permanecer en las bases de datos de LIFTIT S.A.S. El área
          responsable en LIFTIT S.A.S., para la protección de datos
          personales que dará trámite a las solicitudes, consultas y
          reclamos relacionados con Habeas Data, es Servicio al Cliente.
        </p>
        <h4>Recepción de solicitudes y Consultas:</h4>
        <ol>
          <li>
            <b>Por medio físico o mediante mail:</b> El Titular o las
            demás personas permitidas por la ley podrán realizar
            consultas, solicitudes o reclamos utilizando un escrito
            dirigido a la empresa y lo enviarán por correo físico a la
            Carrera 13ª # 98-75 (Piso 3) Bogotá D.C., o al correo
            electrónico: hola@liftit.co. Los tiempos de respuesta serán
            de diez días hábiles para consultas y de quince (15) días
            hábiles para reclamos.
          </li>
          <li>
            Por medio telefónico: Las consultas, peticiones y reclamos
            recibidos por este medio serán tramitados por la empresa y
            su respuesta será dada por escrito a la dirección de
            notificación aportada por el Titular. Los tiempos de
            respuesta serán de diez (10) días hábiles para consultas y
            de quince (15) días hábiles para reclamos.
          </li>
          <li>
            Solicitud para conocimiento, actualización y rectificación
            de datos personales: Esta procederá frente a datos
            parciales, inexactos, incompletos, fraccionados, que
            induzcan a error, o aquellos cuyo tratamiento esté
            expresamente prohibido o no haya sido autorizado por el
            Titular. La solicitud que realice el Titular sobre el
            particular, deberá ser enviada por correo físico a la
            Carrera 13ª # 98-75 (Piso 3) Bogotá D.C., o al correo
            electrónico: hola@liftit.co. Los tiempos de respuesta serán
            de diez (10) días hábiles para consultas y de quince (15)
            días hábiles para reclamos.
          </li>
          <li>
            Solicitud para revocar la autorización y/o solicitar la
            supresión del dato: Esta procederá a solicitud del Titular
            (excepto en los casos donde la ley señala expresamente que
            no procede) o cuando la Superintendencia de Industria y
            Comercio así lo determine, previa verificación del
            incumplimiento por parte de LIFTIT S.A.S., de los
            principios, derechos y garantías constitucionales y legales.
            La solicitud que realice el Titular sobre el particular,
            deberá ser enviada por correo físico a la Carrera 13ª #
            98-75 (Piso 3) Bogotá D.C, o al correo electrónico:
            hola@liftit.co. Los tiempos de respuesta serán de diez (10)
            días hábiles para consultas y de quince (15) días hábiles
            para reclamos.
          </li>
          <li>
            Reclamos por inexactitud o incumplimiento de obligaciones:
            El Titular o sus causahabientes que consideren que la
            información contenida en una base de datos debe ser objeto
            de corrección, actualización o supresión, o cuando
            consideren que se ha presentado un incumplimiento de
            cualquiera de los deberes contenidos en la Ley 1581 de 2012,
            podrán presentar reclamo ante LIFTIT S.A.S. por correo
            físico a la Carrera 13ª # 98-75 (Piso 3) Bogotá D.C, o al
            correo electrónico: hola@liftit.co. Dicho reclamo será
            tramitado bajo las siguientes reglas:
            <ul>
              <li>
                El reclamo se formulará mediante solicitud dirigida a
                LIFTIT S.A.S., con la identificación del Titular, la
                descripción de los hechos que dan lugar al reclamo, la
                dirección, y acompañando los documentos que se quiera
                hacer valer. Si el reclamo resulta incompleto, se
                requerirá al interesado dentro de los cinco (5) días
                siguientes a la recepción del reclamo para que subsane
                las fallas. Transcurridos Diez (10) días desde la fecha
                del requerimiento, sin que el solicitante presente la
                información requerida, se entenderá que ha desistido del
                reclamo. En caso de que quien reciba el reclamo no sea
                competente para resolverlo, dará traslado a quien
                corresponda en un término máximo de dos (2) días hábiles
                e informará de la situación al interesado.
              </li>
              <li>
                Una vez recibido el reclamo completo, se incluirá en la
                base de datos una leyenda que diga “reclamo en trámite”
                y el motivo del mismo, en un término no mayor a dos (2)
                días hábiles. Dicha leyenda deberá mantenerse hasta que
                el reclamo sea decidido.
              </li>
              <li>
                El término máximo para atender el reclamo será de quince
                (15) días hábiles contados a partir del día siguiente a
                la fecha de su recibo. Cuando no fuere posible atender
                el reclamo dentro de dicho término, se informará al
                interesado los motivos de la demora y la fecha en que se
                atenderá su reclamo, la cual en ningún caso podrá
                superar los ocho (8) días hábiles siguientes al
                vencimiento del primer término.
              </li>
              <li>
                Cuando exista un reclamo, LIFTIT S.A.S., deberá informar
                a los terceros con quienes hubiere compartido los datos
                personales que están siendo objeto de reclamo, que dicho
                reclamo existe y que se encuentra en trámite. Adicional
                a lo anterior, deberá insertar en la base de datos la
                leyenda “información en discusión judicial” una vez
                notificado por parte de la autoridad competente sobre
                procesos judiciales relacionados con la calidad del dato
                personal. Mientras la información este siendo
                controvertida por su Titular ante la autoridad, LIFTIT
                S.A.S., no podrá circularla con terceros.
              </li>
            </ul>
          </li>
        </ol>
        <h4>Actualización de esta política</h4>
        <p>
          LIFTIT S.A.S., se reserva el derecho de actualizar la presente
          Política cuando así lo requiera de acuerdo a sus
          procedimientos internos y ejercicio de sus actividades o según
          los parámetros de la normativa vigente a la fecha de la
          actualización.
        </p>
        <h4>Ley y Jurisdicción:</h4>
        <p>
          Toda interpretación, actuación judicial o administrativa
          derivada del tratamiento de los datos personales estará sujeta
          a las normas de protección personal establecidas en la
          República de Colombia y las autoridades administrativas o
          jurisdiccionales competentes para la resolución de cualquier
          inquietud, queja o demanda sobre las mismas serán las de la
          República de Colombia.
        </p>
        <h4>Fecha de entrada en vigencia de la Política: </h4>
        <p>
          La presente política entra en vigencia el día 01 de Octubre de
          2016.{' '}
        </p>
      </header>
    </div>
  </div>
)
export default withStyles(s)(DataTreatment)
