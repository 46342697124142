import { PropTypes } from 'prop-types'

const serviceAddress = {
  city: PropTypes.string,
  department: PropTypes.string,
  formatted_address: PropTypes.string,
  lon: PropTypes.number,
  address: PropTypes.string,
  user_address: PropTypes.string,
  dep_state: PropTypes.string,
  lat: PropTypes.number,
  country: PropTypes.string,
  countryCode: PropTypes.string,
}

const User = {
  name: PropTypes.string,
  numDoc: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
}

const Modal = {
  show: PropTypes.bool,
  form: PropTypes.bool,
  renderForm: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.func,
  close: PropTypes.func,
}

const currentService = {
  id: PropTypes.string,
  responseTime: PropTypes.number,
}

const serviceInfo = {
  data: PropTypes.array,
}

const timeWindow = {
  start: PropTypes.number,
  end: PropTypes.number,
}

const serviceType = {
  id: PropTypes.number,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  totalDescription: PropTypes.string,
  maxWeight: PropTypes.number,
  maxVolume: PropTypes.number,
  responseTime: PropTypes.number,
  timeWindow: PropTypes.shape(timeWindow),
}

const propTypesModels = {
  serviceAddress,
  User,
  Modal,
  currentService,
  serviceInfo,
  serviceType,
}

export default propTypesModels
