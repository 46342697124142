const getThemeColor = theme => {
  if (theme && theme === 'orange') {
    return '#ff9160'
  }
  if (theme && theme === 'gray') {
    return '#a2a2a2'
  }
  if (theme && theme === 'dark') {
    return '#535353'
  }
  if (theme && theme === 'blue') {
    return '#2b3492'
  }
  return '#ffffff'
}

export default getThemeColor
