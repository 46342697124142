/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import HamburgerMenu from 'react-hamburger-menu'
import { withNamespaces } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import s from './HeaderWrapper.css'
import LoggedUserItem from './components/LoggedUserItem'
import getThemeColor from './utils/getThemeColor'
import history from '../../history'
import SessionActions from '../../reducers/session'

// const whiteLogoLiftit =
//   'https://s3.amazonaws.com/liftit-assets/v2/images/liftitLogoWhite.svg';
// const orangeLogoLiftit =
//   'https://s3.amazonaws.com/liftit-assets/landings/logo-naranja.svg';
// const miCities = {
//   type: 'route',
//   path: '/cities',
//   rawLabel: 'menu.city',
//   itemView: 'cities',
// };
const miBlog = {
  type: 'link',
  path: 'https://blog.liftit.co',
  rawLabel: 'menu.blog',
  itemView: 'blog',
}
const miLogin = {
  type: 'login',
  path: '/login',
  rawLabel: 'menu.enter',
}
const menusByView = {
  main: [miBlog, miLogin],
  login: [miBlog],
  profile: [miBlog, miLogin],
  book: [miBlog, miLogin],
  cities: [miBlog, miLogin],
  tracking: [miBlog],
}

const MenuItemButton = styled.button`
  font-family: 'Avenir';
  background-color: transparent;
  color: ${({ color }) => color || '#ffffff'};
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 0;
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  @media (min-width: 1200px) {
    margin-left: 50px;
  }
  &:hover {
    color: #81da97 !important;
  }
  &.active {
    border-bottom: 2px solid #ff9160;
  }
  &.login {
    color: ${({ color }) => color || '#ffffff'};
    border: 2px solid ${({ color }) => color || '#ffffff'};
    border-radius: 4px;
    padding: 5px 24px;
    @media (max-width: 768px) {
      border: none;
      color: #fff;
      text-align: left;
      padding: 20px 0;
      width: 100%;
      border-radius: 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }
  &.login:hover {
    border: 2px solid #81da97 !important;
  }
`

const MenuItem = ({
  type,
  label,
  path,
  itemView,
  view,
  theme,
  user,
  logout,
  profile,
  services,
}) => {
  const baseClass =
    view && itemView && view === itemView
      ? 'menu-item active '
      : 'menu-item'
  switch (type) {
    case 'route':
      return (
        <MenuItemButton
          className={baseClass}
          color={getThemeColor(theme)}
          onClick={() => history.push({ pathname: path || '/' })}
        >
          {label}
        </MenuItemButton>
      )
    case 'link':
      return (
        <MenuItemButton
          className={baseClass}
          color={getThemeColor(theme)}
          onClick={() => {
            window.location.href = path || 'https://liftit.co'
          }}
        >
          {label}
        </MenuItemButton>
      )
    case 'login':
      if (user && user.name) {
        return (
          <LoggedUserItem
            text={user.name}
            theme={theme}
            services={services}
            logout={logout}
            profile={profile}
          />
        )
      }

      return (
        <MenuItemButton
          color={
            getThemeColor(theme) === '#ffffff' ? '#ffffff' : '#737373'
          }
          className="login"
          onClick={() => history.push({ pathname: path || '/' })}
        >
          {label}
        </MenuItemButton>
      )
    default:
      break
  }

  return null
}

const MobileMenuItemButton = styled.button`
  font-family: 'Avenir';
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 12px 10px;
  color: #ffffff;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  outline: none;
`

const MobileMenuItem = ({
  type,
  label,
  path,
  closeMobileMenu,
  theme,
  user,
  logout,
  profile,
  services,
}) => {
  if (type === 'route') {
    return (
      <MobileMenuItemButton
        className="menu-item"
        onClick={() => [
          history.push({ pathname: path || '/' }),
          closeMobileMenu(),
        ]}
      >
        {label}
      </MobileMenuItemButton>
    )
  }

  if (type === 'link') {
    return (
      <MobileMenuItemButton
        className="menu-item"
        onClick={() => {
          closeMobileMenu()
          window.location.href = path || 'https://liftit.co'
        }}
      >
        {label}
      </MobileMenuItemButton>
    )
  }

  if (type === 'login') {
    if (user && user.name) {
      return (
        <LoggedUserItem
          text={user.name}
          theme={theme}
          services={services}
          logout={logout}
          profile={profile}
        />
      )
    }

    return (
      <MenuItemButton
        color="#ffffff"
        className="login"
        onClick={() => history.push({ pathname: path || '/' })}
      >
        {label}
      </MenuItemButton>
    )
  }

  return null
}

const HeaderWrapperContainer = styled.div`
  width: 100%;
  .header-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .menu-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 50;
      background-color: transparent;
      transition: background-color 0.2s ease;
      &.open {
        background-color: #2b3492;
        transition: background-color 0.2s ease;
        .header-menu {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
      .header-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 64px;
        margin: 0 auto;
        @media (min-width: 768px) {
          height: 82px;
        }
        @media (min-width: 1200px) {
          width: 1050px;
        }
        .brand {
          cursor: pointer;
          .brand-link {
            .brand-logo {
              height: 25px;
            }
            .orangeLogoLiftit {
              font-size: 65px;
              color: #2b3492;
            }
            .whiteLogoLiftit {
              font-size: 65px;
              color: #ffffff;
            }
          }
        }
        .desktop-menu {
          display: none;
          @media (min-width: 768px) {
            display: flex;
            align-items: center;
          }
        }
        .mobile-menu-toggler {
          @media (min-width: 768px) {
            display: none;
          }
        }
      }
    }
    .mobile-menu-container {
      position: absolute;
      background-color: #2b3492;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: -1;
      transition: ease opacity 0.2s, ease z-index 0s 0.2s;
      @media (min-width: 768px) {
        top: 82px;
      }
      &.open {
        opacity: 1;
        z-index: 50;
        transition: ease opacity 0.2s;
      }
      .mobile-menu {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 90%;
        margin: 0 auto;
        overflow-y: auto;
      }
    }
    .children {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, ease opacity 0.2s;
      &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, ease opacity 0.2s;
        max-height: 1050px;
      }
    }
    .blue_menu {
      background-color: #2b3492;
      box-shadow: 7px 7px 7px #e6e6e6;
    }
  }
`

class HeaderWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenMobileMenu: false,
      // eslint-disable-next-line react/no-unused-state
      showExtraContent: false,
    }

    this.closeMobileMenu = this.closeMobileMenu.bind(this)
    this.hideExtraContent = this.hideExtraContent.bind(this)
  }

  componentDidMount = () => {
    const { showExtraContent } = this.props
    if (showExtraContent) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ showExtraContent: true })
    }
  }

  closeMobileMenu() {
    this.setState({ isOpenMobileMenu: false })
  }

  hideExtraContent() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ showExtraContent: false })
  }
  render() {
    const {
      children,
      view,
      theme,
      t,
      user,
      logout,
      background,
    } = this.props
    const { isOpenMobileMenu } = this.state
    const liftitLogo =
      theme && ['orange', 'blue', 'gray', 'dark'].includes(theme)
        ? 'orangeLogoLiftit'
        : 'whiteLogoLiftit'

    return (
      <HeaderWrapperContainer>
        <div className="header-wrapper">
          <div
            className={
              (isOpenMobileMenu
                ? 'menu-container open '
                : 'menu-container ') + background
            }
          >
            <div className="header-menu">
              <div className="mobile-menu-toggler">
                <HamburgerMenu
                  isOpen={isOpenMobileMenu}
                  menuClicked={() =>
                    this.setState({
                      isOpenMobileMenu: !isOpenMobileMenu,
                    })
                  }
                  color={
                    isOpenMobileMenu ? '#ffffff' : getThemeColor(theme)
                  }
                  animationDuration={0.2}
                  width={28}
                  height={22}
                />
              </div>
              <div className="brand">
                <div
                  className="brand-link"
                  onClick={() => history.push({ pathname: '/' })}
                >
                  <span className={`liftit-logo ${liftitLogo}`} />
                </div>
              </div>
              <div className="desktop-menu">
                {view &&
                  menusByView[view] &&
                  menusByView[view].map((item, index) => (
                    <MenuItem
                      key={`desktop-menu-item-${index}`}
                      view={view}
                      theme={theme}
                      label={t(item.rawLabel)}
                      user={user}
                      logout={() => {
                        logout()
                        history.push({ pathname: '/' })
                      }}
                      profile={() => {
                        history.push({ pathname: '/profile' })
                      }}
                      services={() => {
                        history.push({ pathname: '/services' })
                      }}
                      {...item}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div
            className={
              isOpenMobileMenu
                ? 'mobile-menu-container open'
                : 'mobile-menu-container'
            }
          >
            <div className="mobile-menu">
              {view &&
                menusByView[view] &&
                menusByView[view].map((item, index) => (
                  <MenuItem
                    key={`mobile-menu-item-${index}`}
                    view={view}
                    label={t(item.rawLabel)}
                    closeMobileMenu={this.closeMobileMenu}
                    user={user}
                    logout={() => {
                      logout()
                      history.push({ pathname: '/' })
                    }}
                    profile={() => {
                      history.push({ pathname: '/login' })
                    }}
                    {...item}
                  />
                ))}
            </div>
          </div>
          <div
            className={
              isOpenMobileMenu ? 'children hidden' : 'children'
            }
          >
            {children}
          </div>
        </div>
      </HeaderWrapperContainer>
    )
  }
}

MenuItem.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string,
  itemView: PropTypes.string,
  view: PropTypes.string,
  theme: PropTypes.string,
  logout: PropTypes.func,
  profile: PropTypes.func,
  services: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    numDoc: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
}

MobileMenuItem.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string,
  closeMobileMenu: PropTypes.func,
  theme: PropTypes.string,
  logout: PropTypes.func,
  profile: PropTypes.func,
  services: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string,
    numDoc: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
}

const mapStateToProps = state => ({
  user: state.session.user,
  isLoggedIn: state.session.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(SessionActions.logout()),
  trackClick: evt =>
    dispatch({
      type: 'TRACK_ON_MIXPANEL',
      analytics: {
        eventType: 'track',
        eventPayload: {
          event: evt,
        },
      },
    }),
})

const headerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderWrapper)

const headerWrapperWithNS = withNamespaces(['components'])(
  headerWrapper,
)

export default withStyles(s)(headerWrapperWithNS)
