exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26fc3{margin-top:90px;width:100%;max-width:1024px;display:grid;grid-template-columns:1fr 1fr 1fr 1fr;grid-gap:40px}._26fc3 ._2DE3n{-ms-flex-item-align:center;align-self:center;justify-self:center}@media (max-width:768px){._26fc3{grid-template-columns:1fr 1fr;grid-gap:20px;margin-top:15px}._26fc3 ._2DE3n{margin-top:30px}}", ""]);

// exports
exports.locals = {
	"client": "_26fc3",
	"item": "_2DE3n"
};