import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from './client.css'
import history from '../../../../history'

const Corona =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Corona.png'
const Elektra =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Elektra.png'
const Exito =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Exito.png'
const HomeCenter =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Homecenter.png'
const Orbis =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Orbis.png'
const Pqp = 'https://s3.amazonaws.com/liftit-assets/v2/images/PQP.png'
const Rappi =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Rappi.png'
const Walmart =
  'https://s3.amazonaws.com/liftit-assets/v2/images/Walmart.png'

class Client extends React.Component {
  render() {
    return (
      <div className={s.client}>
        <div className={s.item}>
          {' '}
          <img src={Rappi} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={Exito} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={HomeCenter} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={Elektra} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={Orbis} alt="" width="100%" />
        </div>
        <div className={s.item}>
          {' '}
          <img src={Walmart} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={Pqp} alt="" width="100%" />{' '}
        </div>
        <div className={s.item}>
          {' '}
          <img src={Corona} alt="" width="100%" />
        </div>
      </div>
    )
  }

  goHome = () => {
    history.push({ pathname: '/' })
  }
}

export default withStyles(s)(Client)
