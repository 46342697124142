import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { withNamespaces } from 'react-i18next'
import PropTypes from 'prop-types'
import CardSlider from './CardSlider'

const ArrowNext =
  'https://s3.amazonaws.com/liftit-assets/v2/images/arrow_next.png'
const ArrowRev =
  'https://s3.amazonaws.com/liftit-assets/v2/images/arrow_rev.png'

const ClientReviewContainer = styled.div`
  width: 90%;
  height: 600px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 100px;
  align-items: center;
  @media (max-width: 1100px) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    margin-top: 0px;
    .slick-slider {
      margin-top: 0px;
    }
  }
  .slick-prev {
    width: 50px;
    height: 20px;
    transform: scaleY(-1);
    transform: translate(40%, -50%);
    background-image: url(${ArrowRev});
    background-repeat: no-repeat;
    background-size: 30px 14px;
    z-index: 100;
  }
  .slick-next {
    width: 50px;
    height: 20px;
    transform: scaleY(-1);
    transform: translate(0, -50%);
    background-image: url(${ArrowNext});
    background-repeat: no-repeat;
    background-size: 30px 14px;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }
`

const StyledSlider = styled(Slider)`
  margin-top: 40px;
  width: 100%;
`

class Ratings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile: false,
    }
  }

  componentDidMount() {
    const changeWidth = x => {
      if (x.matches) {
        this.setState({
          mobile: true,
        })
      } else {
        this.setState({
          mobile: false,
        })
      }
    }
    const x = window.matchMedia('(max-width: 1100px)')
    x.addListener(changeWidth)
    changeWidth(x)
  }

  render() {
    const { t } = this.props
    return (
      <ClientReviewContainer>
        <StyledSlider
          dots
          autoplay
          autoplaySpeed={5000}
          arrows
          draggable={false}
          touchMove={false}
          infinite
          slidesToShow={this.state.mobile ? 1 : 3}
        >
          <CardSlider
            urlImage={t('cardSlider.cards.card-one.image')}
            title={t('cardSlider.cards.card-one.name')}
            subTitle={t('cardSlider.cards.card-one.ocupation')}
            info={t('cardSlider.cards.card-one.paragraph')}
          />
          <CardSlider
            urlImage={t('cardSlider.cards.card-two.image')}
            title={t('cardSlider.cards.card-two.name')}
            subTitle={t('cardSlider.cards.card-two.ocupation')}
            info={t('cardSlider.cards.card-two.paragraph')}
          />
          <CardSlider
            urlImage={t('cardSlider.cards.card-three.image')}
            title={t('cardSlider.cards.card-three.name')}
            subTitle={t('cardSlider.cards.card-three.ocupation')}
            info={t('cardSlider.cards.card-three.paragraph')}
          />
          <CardSlider
            urlImage={t('cardSlider.cards.card-four.image')}
            title={t('cardSlider.cards.card-four.name')}
            subTitle={t('cardSlider.cards.card-four.ocupation')}
            info={t('cardSlider.cards.card-four.paragraph')}
          />
          <CardSlider
            urlImage={t('cardSlider.cards.card-five.image')}
            title={t('cardSlider.cards.card-five.name')}
            subTitle={t('cardSlider.cards.card-five.ocupation')}
            info={t('cardSlider.cards.card-five.paragraph')}
          />
        </StyledSlider>
      </ClientReviewContainer>
    )
  }
}

Ratings.propTypes = {
  t: PropTypes.func,
}

export default withNamespaces('components')(Ratings)
