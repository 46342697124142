import styled from 'styled-components'

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${props => props.align || 'center'};
  flex-direction: column;
  background-color: #ffffff;
  font-family: Avenir;
  padding: 0 20px;
  border-radius: 5px;
`
export const NextButton = styled.button`
  height: 60px;
  width: 100%;
  cursor: pointer;
  background-color: ${props =>
    props.isEnabled ? '#d8dae8' : '#2b3492'};
  border-radius: 3px;
  margin: 30px 0;
  box-sizing: border-box;
  border: 0 solid transparent;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
`
export const BackButton = styled.button`
  height: 50px;
  width: 150px;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 30px;
  outline: none;
`
export const InputArea = styled.textarea`
  height: 300px;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid ${props => (props.error ? '#FF5E5E' : '#e9e9e9')};
  margin: 10px 0;
  padding: 10px;
  resize: none;
  outline: none;
  &:focus {
    border: 1px solid #2b3492;
  }
`
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`
export const ExpressContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`
export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const FormInput = styled.input`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid ${props => (props.error ? '#FF5E5E' : '#e9e9e9')};
  padding: 0 10px;
  outline: none;
  -moz-appearance: textfield;
  &:focus {
    border: 1px solid #2b3492;
  }
`
export const InputWrapper = styled.div`
  width: 47%;
  margin: 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const Label = styled.label`
  height: 15px;
  color: #767676;
  font-family: 'Circular Std';
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  margin-bottom: 5px;
`

export const Select = styled.select`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 0 10px;
  box-sizing: border-box;
`

export const Title = styled.h3`
  height: 25px;
  color: #535353;
  font-family: 'Circular Std';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  margin: 20px 0;
  align-self: flex-start;
  @media (max-width: 350px) {
    margin-bottom: 40px;
  }
`
export const ErrorContainer = styled.div`
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ff5e5e;
  border-radius: 4px;
  background-color: #fafafa;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`
export const ErrorText = styled.div`
  color: #ff5e5e;
  font-family: Avenir;
  font-size: 14px;
  margin-left: 15px;
`
export const ErrorLabel = styled.div`
  background-color: #ff5e5e;
  width: 34px;
  height: 100%;
  display: flex;
  font-size: 24px;
  align-items: center;
  font-family: Avenir;
  color: #ffffff;
  justify-content: center;
`
export const Terms = styled.p`
  color: #767676;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
`
export const TermsHome = styled.p`
  font-size: 14px;
  margin-top: 20px;
  @media (max-width: 768px) {
    text-align: center;
  }
`

export const TermsLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #2b3492;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
`

export const TermsLinkHome = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: white;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
`
