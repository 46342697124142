import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { IntercomAPI } from 'react-intercom'

import CommentSlider from './components/CommentSlider'
import HeaderHome from './components/HeaderHome/index'
import ModalActions from '../../reducers/modal'

import Client from './components/Client'

import s from './Liftit.css'
import colors from '../../utils/constants/colors'
import propTypesModels from '../../utils/constants/propTypesModels'

import ServiceTypes from '../../components/ServiceTypes'
import Footer from '../../components/Footer/Footer'

import SessionActions from '../../reducers/session'
import ServiceActions from '../../reducers/service'

const BackBlueImage =
  'https://s3.amazonaws.com/liftit-assets/v2/images/back1.png'
const BackOrangeImage =
  'https://s3.amazonaws.com/liftit-assets/v2/images/back2.png'
const LineImage =
  'https://s3.amazonaws.com/liftit-assets/v2/images/line.svg'

const Container = styled.div`
  top: -10px;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Circular Std';
`
const Title = styled.h2`
  font-size: 36px;
  color: ${colors.dark_gray};
  font-weight: bold;
  margin: 5px;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 24px;
  }
`
const Image = styled.img`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  object-fit: contain;
  align-self: center;
`
const ImageStep = styled.img`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  object-fit: contain;
  align-self: center;
  @media (max-width: 1100px) {
    width: 90%;
    height: 200px;
  }
`
const BackBlue = styled.img`
  position: absolute;
  top: 3900px;
  width: 100%;
  align-self: center;
  pointer-events: none;
`
const BackOrange = styled.img`
  position: absolute;
  top: 5200px;
  width: 100%;
  align-self: center;
`
const Subtitle = styled.h3`
  color: ${colors.gray};
  font-size: 20px;
  font-weight: 300;
  font-family: 'Avenir';
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 13px;
    width: 90%;
  }
`
const TitleBottomLine = styled.div`
  width: 70px;
  height: 3px;
  background: ${colors.liftit};
`
const WhySelectLiftitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  @media (max-width: 600px) {
    margin-top: -60px;
  }
`
const WhySelectLiftitCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 50px 60px 40px 60px;
  max-width: 950px;
  box-shadow: 0 14px 40px -5px rgba(0, 0, 0, 0.14);
  background-color: ${colors.white};
  z-index: 1;
  margin-top: 70px;
  @media (max-width: 1100px) {
    box-shadow: none;
  }
`
const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ItemTitle = styled.h3`
  color: ${colors.dark_gray};
  font-size: 20px;
  text-align: center;
  font-family: Circular Std;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0px;
`
const ItemSubtitle = styled.h4`
  color: ${colors.gray};
  font-size: 16px;
  max-width: 250px;
  text-align: center;
  font-family: 'Avenir';
  font-weight: 400;
  margin-top: 10px;
  line-height: 24px;
`
const SecondTitleBottomLine = styled.div`
  width: 70px;
  height: 3px;
  margin: 20px 0 25px 0;
  background: ${colors.liftit};
`
const ProgressContainer = styled.div`
  width: 90%;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 290px;
  @media (max-width: 1100px) {
    align-items: center;
  }
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`

const ProgressContainerVehicle = styled.div`
  width: 90%;
  max-width: 1050px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 100px;
  align-items: center;
  @media (max-width: 1100px) {
    align-items: center;
  }
  @media (max-width: 600px) {
    margin-top: -60px;
  }
`
const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.right ? 'row-reverse' : 'row')};
  margin-top: 60px;
  margin-bottom: -110px;
  z-index: 1;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
  }
`
const StepContainer2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.right ? 'row-reverse' : 'row')};
  margin-top: 200px;
  margin-bottom: -130px;
  z-index: 1;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
  }
`
const StepContainer3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.right ? 'row-reverse' : 'row')};
  margin-top: 130px;
  margin-bottom: -130px;
  z-index: 1;
  align-items: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
  }
`
const ElbowLine = styled.img`
  position: absolute;
  top: ${props => props.top || '500px'};
  left: 250px;
  width: 650px;
  align-self: center;
  transform: ${props => (props.invert ? 'scaleX(-1)' : 'none')};
  filter: ${props => (props.invert ? 'FlipH' : 'none')};
  @media (max-width: 1100px) {
    display: none;
  }
`
const TextStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: ${props => (props.right ? 0 : '100px')};
  margin-right: ${props => (props.right ? '100px' : 0)};
  @media (max-width: 1100px) {
    margin: 0;
    align-items: center;
  }
`
const TitleStep = styled.h3`
  color: ${colors.dark_gray};
  font-size: 36px;
  font-family: Circular Std;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 20px;
  }
`
const TextStep = styled.h4`
  color: ${colors.medium_gray};
  font-size: 16px;
  max-width: 430px;
  font-family: 'Avenir';
  font-weight: 400;
  margin-top: 0;
  line-height: 22px;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 13px;
    max-width: 80%;
  }
`
const ClientReviewContainer = styled.div`
  margin-top: 250px;
  width: 90%;
  height: 600px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  @media (max-width: 1100px) {
    margin-top: 20px;
  }
`
const HaveVehicleContainer = styled.div`
  margin: 180px 0;
  width: 100%;
  max-width: 1075px;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 50% auto;
  height: 502px;
  flex-direction: row;
  position: relative;
  background: url(${process.env.ASSETS_URL}/btc-lifters.jpg) no-repeat
    center center;
  background-size: cover;
  justify-content: space-between;
  @media (max-width: 1100px) {
    grid-template-columns: 5% auto;
    width: 90%;
    height: 300px;
    border-radius: 8px;
  }
  @media (max-width: 600px) {
    margin-top: 35px;
    margin-bottom: 0px;
  }
`
const TextHaveVehicleContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin-left: ${props => (props.right ? 0 : '100px')};
  margin-right: ${props => (props.right ? '100px' : 0)};
  @media (max-width: 1100px) {
    margin: 0;
    align-items: center;
    width: 100%;
  }
`
const TitleVehicle = styled.h3`
  padding: 0px 10px;
  color: white;
  margin: 7px 0;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
  line-height: 35px;
  @media (max-width: 1100px) {
    font-size: 26px;
    margin-bottom: 7px;
  }
`
const InfoVehicle = styled.h4`
  padding: 0px 10px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: white;
  margin: 7px 0;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    font-size: 12px;
    margin-bottom: 7px;
  }
`
const RegisterButtonWhite = styled.a`
  background: white;
  color: ${colors.liftit};
  height: 54px;
  justify-content: center;
  align-items: center;
  width: 238px;
  border-radius: 3px;
  display: flex;
  font-family: 'Circular Std';
  font-size: 18px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline-color: #ff9690;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    margin-bottom: 7px;
    height: 37.8px;
    width: 166.6px;
  }
`

class Liftit extends React.Component {
  constructor(props) {
    super(props)
    const { saveLocation } = props
    this.state = {
      saveLocation,
    }
    this.props.closeModal()
  }

  componentDidMount() {
    // const handle = position => {
    //   if (navigator.geolocation) {
    //   this.state.saveLocation(position.coords);
    //   }
    // };
    // navigator.geolocation.getCurrentPosition(handle, () => {});
    if (!this.props.country) {
      this.state.saveLocation()
    }
    this.props.cleanState()
  }

  getLiftersUrl = country => {
    switch (country) {
      case 'co':
        return 'https://liftit.typeform.com/to/vs0PWP'
      case 'mx':
        return process.env.TYPEFORM_URL_MEX
      case 'cl':
        return process.env.TYPEFORM_URL_CHL
      case 'ec':
        return process.env.TYPEFORM_URL_ECU
      case 'br':
        return process.env.TYPEFORM_URL_BRA
      default:
        return 'https://liftit.typeform.com/to/vs0PWP'
    }
  }

  render() {
    IntercomAPI('update', {
      email: this.props.user ? this.props.user.email : '',
      user_id: this.props.user ? this.props.user.email : '',
      name: this.props.user ? this.props.user.name : '',
      phone: this.props.user ? this.props.user.phone : '',
      numDoc: this.props.user ? this.props.user.numDoc : '',
    })

    const { t } = this.props

    return (
      <Container>
        <HeaderHome trackClick={this.props.trackClick} />
        <ServiceTypes negativeMargin />
        <ProgressContainerVehicle>
          <Title>{t('components:clients.title')}</Title>
          <TitleBottomLine />
          <Client />
        </ProgressContainerVehicle>

        <WhySelectLiftitContainer>
          <WhySelectLiftitCard>
            <Title>{t('components:whyLiftit.title')}</Title>
            <SecondTitleBottomLine />
            <ItemsContainer>
              <Item>
                <Image
                  width="120px"
                  height="120px"
                  src={t('components:whyLiftit.cards.card-one.image')}
                />
                <ItemTitle>
                  {t('components:whyLiftit.cards.card-one.title')}
                </ItemTitle>
                <ItemSubtitle>
                  {t('components:whyLiftit.cards.card-one.paragraph')}
                </ItemSubtitle>
              </Item>
              <Item>
                <Image
                  width="120px"
                  height="120px"
                  src={t('components:whyLiftit.cards.card-two.image')}
                />
                <ItemTitle>
                  {t('components:whyLiftit.cards.card-two.title')}
                </ItemTitle>
                <ItemSubtitle>
                  {t('components:whyLiftit.cards.card-two.paragraph')}
                </ItemSubtitle>
              </Item>
              <Item>
                <Image
                  width="120px"
                  height="120px"
                  src={t('components:whyLiftit.cards.card-three.image')}
                />
                <ItemTitle>
                  {t('components:whyLiftit.cards.card-three.title')}
                </ItemTitle>
                <ItemSubtitle>
                  {t('components:whyLiftit.cards.card-three.paragraph')}
                </ItemSubtitle>
              </Item>
            </ItemsContainer>
          </WhySelectLiftitCard>
        </WhySelectLiftitContainer>
        <BackBlue style={{ right: 0 }} src={BackBlueImage} />
        <BackOrange style={{ right: 0 }} src={BackOrangeImage} />
        <ProgressContainer>
          <Title>{t('sections.howWork.title')}</Title>
          <TitleBottomLine />
          <Subtitle>{t('sections.howWork.paragraph')}</Subtitle>

          <StepContainer>
            <ImageStep
              width="450px"
              height="371px"
              src={t('sections.howWork.steps.step-one.image')}
            />
            <TextStepContainer>
              <TitleStep>
                {t('sections.howWork.steps.step-one.title')}
              </TitleStep>
              <TextStep>
                {t('sections.howWork.steps.step-one.paragraph')}
              </TextStep>
            </TextStepContainer>
          </StepContainer>
          <StepContainer2 right>
            <ImageStep
              width="450px"
              height="371px"
              src={t('sections.howWork.steps.step-two.image')}
            />
            <TextStepContainer right>
              <TitleStep>
                {t('sections.howWork.steps.step-two.title')}
              </TitleStep>
              <TextStep>
                {t('sections.howWork.steps.step-two.paragraph')}
              </TextStep>
            </TextStepContainer>
          </StepContainer2>
          <StepContainer3>
            <ImageStep
              width="419px"
              height="450px"
              src={t('sections.howWork.steps.step-three.image')}
            />
            <TextStepContainer>
              <TitleStep>
                {t('sections.howWork.steps.step-three.title')}
              </TitleStep>
              <TextStep>
                {t('sections.howWork.steps.step-three.paragraph')}
              </TextStep>
            </TextStepContainer>
          </StepContainer3>
          <ElbowLine top="500px" invert src={LineImage} />
          <ElbowLine top="1000px" src={LineImage} />
        </ProgressContainer>
        <ClientReviewContainer>
          <Title>{t('components:cardSlider.title')}</Title>
          <TitleBottomLine />
          <CommentSlider />
        </ClientReviewContainer>

        <HaveVehicleContainer>
          <div />
          <TextHaveVehicleContainer>
            <TitleVehicle>{t('sections.drivers.title')}</TitleVehicle>
            <InfoVehicle>{t('sections.drivers.paragraph')}</InfoVehicle>
            <RegisterButtonWhite
              href={this.getLiftersUrl(this.props.country)}
              target="_blank"
            >
              {t('sections.drivers.buttonText')}
            </RegisterButtonWhite>
          </TextHaveVehicleContainer>
        </HaveVehicleContainer>
        <Footer />
      </Container>
    )
  }
}

Liftit.propTypes = {
  saveLocation: PropTypes.func.isRequired,
  country: PropTypes.string,
  trackClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  cleanState: PropTypes.func,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape(propTypesModels.User),
}

const mapStateToDispatch = dispatch => ({
  cleanState: () => dispatch(ServiceActions.cleanState()),
  saveLocation: () => dispatch(SessionActions.setLocation()),
  closeModal: () => dispatch(ModalActions.closeModal()),
  trackClick: event =>
    dispatch({
      type: 'TRACK_ON_MIXPANEL',
      analytics: {
        eventType: 'track',
        eventPayload: {
          event,
        },
      },
    }),
})

const mapStateToProps = state => ({
  country: state.session.country,
  user: state.session.user,
})

const liftit = connect(
  mapStateToProps,
  mapStateToDispatch,
)(withStyles(s)(Liftit))

export default withNamespaces(['home', 'components'])(liftit)
