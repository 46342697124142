const colors = {
  liftit: '#2b3492',
  gray: '#A0A0A0',
  medium_gray: '#767676',
  dark_gray: '#535353',
  dark_gray_2: '#272634',
  light_gray: '#ECECEC',
  white: '#FFFFFF',
}

export default colors
