import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import { withNamespaces } from 'react-i18next'
import styled from 'styled-components'
import { IntercomAPI } from 'react-intercom'

import ServiceActions from '../../reducers/service'
import ModalActions from '../../reducers/modal'
import s from './ServiceTypes.css'
import Card from '../Card/Card'
import history from '../../history'
import propTypesModels from '../../utils/constants/propTypesModels'

const RegisterButtonWhite = styled.div`
  background: white;
  color: #4c4cc3;
  height: 54px;
  width: 238px;
  justify-content: center;
  align-items: center;
  width: 238px;
  border-radius: 3px;
  display: flex;
  font-family: 'Circular Std';
  font-size: 18px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline-color: #ff9690;
  }
  @media (max-width: 1100px) {
    height: 35px;
    width: 183.56px;
    font-family: 'Circular Std';
    font-size: 12.6px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
  }
`
const Intercom = user => {
  if (user) {
    IntercomAPI('update', {
      email: user ? user.email : '',
      user_id: user ? user.email : '',
      name: user ? user.name : '',
      phone: user ? user.phone : '',
      numDoc: user ? user.numDoc : '',
    })
  }
}
const ServiceTypes = ({
  negativeMargin,
  serviceTypes,
  selectService,
  showForm,
  currentOriginAddress,
  currentDestinationAddress,
  currentStep,
  t,
  hideService,
  serviceInfo,
  user,
  country,
}) => (
  <div
    id="start-now1"
    className={
      negativeMargin ? s.container__servicio_nm : s.container__servicio
    }
  >
    <div className={s.servicio}>
      <div className={s.servicio__info}>
        <div className={s.info__title}>
          {t('serviceTypes.title').toUpperCase()}
        </div>
        {/* <div className={s.servicio__divider}>
          <div className={s.divider__item} />
        </div> */}
        <div className={s.info__text}>{t('serviceTypes.subtitle')}</div>
      </div>

      <div className={s.container}>
        {serviceTypes.map((serviceType, index) => {
          const serviceTypeIntern = Object.assign({}, serviceType, {
            title: serviceType.title,
            description: t(
              `serviceTypes.types.${serviceType.description}`,
            ),
            totalDescription: t(
              `serviceTypes.types.${serviceType.totalDescription}`,
            ),
          })
          return (
            <Card
              key={`${serviceType.id}-${index}`}
              serviceId={serviceType.id}
              image={serviceType.image}
              title={t(`serviceTypes.types.${serviceType.title}`)}
              description={t(
                `serviceTypes.types.${serviceType.description}`,
              )}
              totalDescription={t(
                `serviceTypes.types.${serviceType.totalDescription}`,
              )}
              handleClick={() =>
                selectService(
                  serviceTypeIntern,
                  Object.keys(serviceInfo).length !== 0 &&
                    currentOriginAddress &&
                    (currentOriginAddress.lat &&
                      currentOriginAddress.lon &&
                      currentDestinationAddress.lat &&
                      currentDestinationAddress.lon),
                  currentStep,
                  serviceType.id,
                  !country || country === '' ? 'co' : country,
                )
              }
            />
          )
        })}
      </div>
    </div>
    {Intercom(user)}
    {!hideService && (
      <div className={s.servicio__empresa}>
        <div className={s.empresa__row} />
        <div className={s.empresa__content}>
          <div className={s.title}>
            {t('serviceTypes.banner.title')}
          </div>
          <div className={s.info}>{t('serviceTypes.banner.info')}</div>
          <RegisterButtonWhite onClick={showForm}>
            {t('serviceTypes.banner.buttonText')}
          </RegisterButtonWhite>
        </div>
      </div>
    )}
  </div>
)

ServiceTypes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  serviceTypes: PropTypes.array.isRequired,
  selectService: PropTypes.func.isRequired,
  serviceInfo: PropTypes.shape(propTypesModels.serviceInfo).isRequired,
  showForm: PropTypes.func.isRequired,
  currentOriginAddress: PropTypes.shape(propTypesModels.serviceAddress)
    .isRequired,
  currentDestinationAddress: PropTypes.shape(
    propTypesModels.serviceAddress,
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  hideService: PropTypes.bool,
  negativeMargin: PropTypes.bool,
  country: PropTypes.string,
  user: PropTypes.shape(propTypesModels.User),
}

const mapStateToDispatch = dispatch => ({
  selectService: (
    service,
    hasAddresses,
    currentStep,
    serviceTypeId,
    countryCode,
  ) => {
    dispatch(ServiceActions.setSelectService(service, true))
    if (hasAddresses && (currentStep < 0 || currentStep > 2)) {
      dispatch(ServiceActions.setCurrentStep(1))
    }
    history.push({
      pathname: hasAddresses
        ? '/express'
        : `/location/${serviceTypeId}/${countryCode}`,
    })
  },
  showForm: () =>
    dispatch(
      ModalActions.setModalContent({
        show: true,
        form: true,
        buttonText: 'Aceptar',
        close: () => dispatch(ModalActions.closeModal()),
      }),
    ),
})

const mapStateToProps = state => ({
  serviceInfo: state.service.serviceInfo,
  serviceTypes: state.service.serviceTypes,
  currentOriginAddress: state.service.currentOriginAddress,
  currentDestinationAddress: state.service.currentDestinationAddress,
  currentStep: state.service.currentStep,
  user: state.session.user,
  country: state.session.country,
})

const serviceTypes = connect(
  mapStateToProps,
  mapStateToDispatch,
)(withStyles(s)(ServiceTypes))

export default withNamespaces(['components'])(serviceTypes)
