import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { withNamespaces } from 'react-i18next'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import PersonIcon from '@material-ui/icons/Person'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

import getThemeColor from '../utils/getThemeColor'

const ServiceIcon =
  'https://s3.amazonaws.com/liftit-assets/v2/images/package.svg'
const UserIcon =
  'https://s3.amazonaws.com/liftit-assets/v2/images/person.svg'

const SessionMenuContainer = styled.div`
  .session-menu-container {
    display: flex;
    margin: 8px 0;
    margin-left: 15px;
    .session-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 4px 10px;
      color: #737373;
      font-family: 'Avenir';
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      svg {
        margin-right: 10px;
      }
      &:hover {
        color: #555555;
      }
    }
  }
  .separator {
    height: 1px;
    background-color: #e5e5e5;
  }
`

const SessionMenu = ({ t, logout, profile, services }) => (
  <SessionMenuContainer>
    <div className="session-menu-container">
      <img src={ServiceIcon} width="18px" alt="" />
      <button className="session-menu-item" onClick={services}>
        {t('menuSession.services')}
      </button>
    </div>
    <div className="session-menu-container">
      <img src={UserIcon} width="18px" alt="" />
      <button className="session-menu-item" onClick={profile}>
        {t('menuSession.profile')}
      </button>
    </div>
    <div className="separator" />
    <div className="session-menu-container">
      <PowerSettingsNewIcon />
      <button onClick={logout} className="session-menu-item">
        {t('menuSession.logout')}
      </button>
    </div>
  </SessionMenuContainer>
)

const LoggedUserButton = styled.div`
  display: inline-block;
  margin-left: 20px;
  @media (min-width: 1200px) {
    margin-left: 50px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  button {
    display: flex;
    align-items: flex-end;
    font-family: 'Avenir';
    background-color: transparent;
    color: ${({ color }) => color || '#ffffff'};
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    .text {
      margin: 0 3px 0 5px;
    }
  }
`

class LoggedUserItem extends React.Component {
  render() {
    const { text, theme, t, logout, profile, services } = this.props

    return (
      <LoggedUserButton color={getThemeColor(theme)}>
        <Popup
          trigger={open => (
            <button>
              <PersonIcon />
              <span className="text">{text}</span>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </button>
          )}
          closeOnDocumentClicks
          contentStyle={{
            padding: '0',
            borderRadius: '4px',
            border: 'none',
          }}
        >
          <SessionMenu
            t={t}
            logout={logout}
            profile={profile}
            services={services}
          />
        </Popup>
      </LoggedUserButton>
    )
  }
}

SessionMenu.propTypes = {
  t: PropTypes.func,
  services: PropTypes.func,
  logout: PropTypes.func,
  profile: PropTypes.func,
}

LoggedUserItem.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.string,
  services: PropTypes.func,
  logout: PropTypes.func,
  profile: PropTypes.func,
  t: PropTypes.func,
}

export default withNamespaces(['components'])(LoggedUserItem)
